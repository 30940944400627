export const environment = {
  production: true,
  imageBucket: 'pocketlab-uploads',
  entryAPIGatewayUrl: 'https://app-dev.thepocketlab.com/api/entry',
  websocketAPIGatewayUrl:
    'wss://4tvaho6d02.execute-api.us-east-1.amazonaws.com/DevStage',
  host: 'https://app-dev.thepocketlab.com',
  entryAPIGatewayName: 'EntryGatewayDev',
  directEntryAPIGatewayUrl: 'https://app-dev.thepocketlab.com/api/direct-entry',
  directEntryAPIGatewayName: 'DirectEntryGatewayDev',
  errorReportAPIEndpointUrl: 'https://app-dev.thepocketlab.com/api/error',
  identityPoolId: 'us-east-1:7656f981-70ad-4d06-8ea3-ec7ca8f1a26f',
  userPoolId: 'us-east-1_gWYokmGmj',
  userPoolWebClientId: '3o5dprcnf5gdp3h9nb9gh5g91m',
  oAuthDomain: 'notebook-dev-auth.auth.us-east-1.amazoncognito.com',
  oneRosterAPIGatewayName: 'OneRosterGatewayDev',
  oneRosterAPIGatewayUrl: 'https://app-dev.thepocketlab.com/api/oneroster',
  opensearchAPIGatewayName: 'OpensearchGatewayDev',
  opensearchAPIGatewayUrl: 'https://app-dev.thepocketlab.com/api/opensearch',
  paymentAPIGatewayName: 'PaymentGatewayDev',
  paymentAPIGatewayUrl: 'https://app-dev.thepocketlab.com/api/payment',
  userStatsAPIGatewayName: 'UserStatsGatewayDev',
  userStatsAPIGatewayUrl: 'https://app-dev.thepocketlab.com/api/stats',
  authAPIGatewayName: 'AuthGatewayDev',
  authAPIGatewayUrl: 'https://auth-dev.thepocketlab.com',
  posthogApiKey: 'phc_VlKsZNFMHZoZr0fDWFQAGcdFaa7OTcazD8pVjAq6VpF',
};
