import {Injectable} from '@angular/core';
import {APIMessages as APIM} from '@common/utils/dist/index';
import {EntryStore} from '@stores/entry.store';
import {
  WebsocketMessageProcessor,
  WSMessageProcessorInput,
  WSMessageProcessorOutput,
} from './websocket-message-processor';
import {NEVER, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {WSMessageReceiverService} from '@services/ws-message-receiver.service';

@Injectable()
export class EntryFetchMessageProcessor implements WebsocketMessageProcessor {
  constructor(
    private entryStore: EntryStore,
    private wsMessageReceiverService: WSMessageReceiverService,
  ) {}

  processMessage(
    params: WSMessageProcessorInput,
  ): Observable<WSMessageProcessorOutput> {
    const msg = params.msg;
    if (msg.int === APIM.RequestIntent.AppRespondToFetch) {
      return this.wsMessageReceiverService
        .receive<APIM.RespondToFetchRequest>(msg)
        .pipe(map((request) => {
          
          this.entryStore.modifyState(request.c);
        }));
    }
    return NEVER;
  }
}
