import { CommProtocol, DigitalPowerMode, Baudrate, } from './connection-types.js';
import * as OdysseyCommandCodes from './odyssey-command-codes.js';
import { SensorDriverHandler } from './sensor-driver-handler.js';
// ascii codes for commands/keywords
export const SET_DIGITAL_FILTER = 'A'.charCodeAt(0);
export const GET_DIGITAL_FILTER = 'a'.charCodeAt(0);
export const FINE_TUNE_ZERO_POINT = 'F'.charCodeAt(0);
export const SET_ZERO_POINT_USING_FRESH_AIR = 'G'.charCodeAt(0);
export const OPERATION_MODE = 'K'.charCodeAt(0);
export const SET_TYPE_AND_NUM_OF_DATA_OUTPUTS = 'M'.charCodeAt(0);
export const SET_CO2_BGROUND_CONCENTRATION = 'P'.charCodeAt(0);
export const GET_NUM_OF_OUTPUT_DATA_FIELDS = 'Q'.charCodeAt(0);
export const SET_PRESSURE_AND_CONCENTRATION_COMPENSATION = 'S'.charCodeAt(0);
export const GET_PRESSURE_AND_CONCENTRATION_COMPENSATION = 's'.charCodeAt(0);
export const SET_ZERO_POINT_USING_NITROGEN = 'U'.charCodeAt(0);
export const MANUALLY_SET_ZERO_POINT = 'u'.charCodeAt(0);
export const SET_ZERO_POINT_USING_KNOWN_GAS = 'X'.charCodeAt(0);
export const SENSOR_INFO = 'Y'.charCodeAt(0);
export const SENSOR_SERIAL_NUM = 'B'.charCodeAt(0);
export const FILTERED_CO2_MEASUREMENT = 'Z'.charCodeAt(0);
export const UNFILTERED_CO2_MEASUREMENT = 'z'.charCodeAt(0);
export const AUTO_ZERO = '@'.charCodeAt(0);
export const SCALING_FACTOR = '.'.charCodeAt(0);
export const CARRIAGE_RETURN = '\r'.charCodeAt(0);
export const TERMINATE = '\n'.charCodeAt(0);
export const SPACE = ' '.charCodeAt(0);
export const UNRECOGNIZED_CMD = '?'.charCodeAt(0);
export const MAX_DIGITAL_FILTER = 65365;
export const MIN_DIGITAL_FILTER = 0;
export const DIGITAL_FILTER_DEFAULT = 16;
export const FILTERED_MASK = 4;
export const UNFILTERED_MASK = 2;
// explorir operation modes
export var ExplorIRMode;
(function (ExplorIRMode) {
    ExplorIRMode[ExplorIRMode["EXPLORIR_MODE_COMMAND"] = 0] = "EXPLORIR_MODE_COMMAND";
    ExplorIRMode[ExplorIRMode["EXPLORIR_MODE_STREAMING"] = 1] = "EXPLORIR_MODE_STREAMING";
    ExplorIRMode[ExplorIRMode["EXPLORIR_MODE_POLLING"] = 2] = "EXPLORIR_MODE_POLLING";
    ExplorIRMode[ExplorIRMode["EXPLORIR_MODE_DEFAULT"] = 1] = "EXPLORIR_MODE_DEFAULT";
})(ExplorIRMode || (ExplorIRMode = {}));
// explorir return codes
export var ExplorIRRetcode;
(function (ExplorIRRetcode) {
    /*
      Error: Invalid Mode Type
      Cause: explorir_set_operation_mode() was given an invalid explorir_mode_t
      Action:	- Check luminox_set_operation_mode() is given a valid explorir_mode_t
      */
    ExplorIRRetcode[ExplorIRRetcode["EXPLORIR_ERR_INVALID_MODE"] = 0] = "EXPLORIR_ERR_INVALID_MODE";
    /*
      Error: UART Response Timeout
      Cause: explorir library function waited too long for response from sensor
      Action: - Check that the sensor is in not in off mode
          - Check that the micro is sending and receiving data from the sensor
      */
    ExplorIRRetcode[ExplorIRRetcode["EXPLORIR_ERR_TIMEOUT"] = 1] = "EXPLORIR_ERR_TIMEOUT";
    ExplorIRRetcode[ExplorIRRetcode["EXPLORIR_ERR_UNRECOGNIZED_COMMAND"] = 2] = "EXPLORIR_ERR_UNRECOGNIZED_COMMAND";
    ExplorIRRetcode[ExplorIRRetcode["EXPLORIR_ERR_INVALID_INPUT"] = 3] = "EXPLORIR_ERR_INVALID_INPUT";
    ExplorIRRetcode[ExplorIRRetcode["EXPLORIR_ERR_DATA_EMPTY"] = 4] = "EXPLORIR_ERR_DATA_EMPTY";
    ExplorIRRetcode[ExplorIRRetcode["EXPLORIR_SUCCESS"] = 5] = "EXPLORIR_SUCCESS";
})(ExplorIRRetcode || (ExplorIRRetcode = {}));
export class ExplrorIRHandler extends SensorDriverHandler {
    constructor() {
        super();
        this.err_code = ExplorIRRetcode.EXPLORIR_SUCCESS; // return code from sensor
    }
    // explorirTX()
    // params:
    // handler - explorir handler instance
    // tx - bytes to send to device
    // size - number of bytes to send to device
    //
    // method to write a command to the sensor
    async explorirTX(tx, size) {
        if (tx == null || size < 1) {
            throw new Error('explorirTX - invalid command');
        }
        await this.conn.writeCommand([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.UART_WRITE,
            this.interfaceID,
            size,
            ...tx,
        ]);
        // TODO: read back DIGITAL_INTERFACE_CMD to validate the command has finished
    }
    // explorirReadData()
    // params:
    // handler - explorir handler instance
    //
    // method to read back response from sensor
    async explorirReadData() {
        if (this.conn == null) {
            throw new Error('explorirReadData - connection object is null');
        }
        // send READ_DIGITAL_INTERFACE_X cmd
        if (this.interfaceID == 1) {
            await this.conn.writeCommand([
                (OdysseyCommandCodes.READ_DIGITAL_INTERFACE_1 << 1) + 1,
            ]);
        }
        else if (this.interfaceID == 2) {
            await this.conn.writeCommand([
                (OdysseyCommandCodes.READ_DIGITAL_INTERFACE_2 << 1) + 1,
            ]);
        }
        else {
            throw new Error('explorirReadData - invalid interface ID');
        }
        // read back and process response
        await this.conn.readCommand().then((result) => {
            this.data = [];
            for (let i = 2; i < result.byteLength; i++) {
                this.data.push(result.getUint8(i));
            }
            this.processData();
        });
    }
    /*
          @brief ExplorIr initialization sequence
  
          @note Gets sensor firmware version and serial number, requests scaling factor, turns sensor off and resets variables
  
          returns whether init was a success or not
      */
    async init(conn, port) {
        this.conn = conn;
        this.interfaceID = port;
        this.current_filtered_co2 = 0;
        this.current_unfiltered_co2 = 0;
        this.digital_filter = DIGITAL_FILTER_DEFAULT;
        this.err_code = ExplorIRRetcode.EXPLORIR_SUCCESS;
        this.err_code = await this.explorir_set_operation_mode(ExplorIRMode.EXPLORIR_MODE_COMMAND);
        this.err_code = await this.explorir_request_sensor_info();
        this.err_code = await this.explorir_request_scaling_factor();
        this.err_code = await this.explorir_set_digital_filter(DIGITAL_FILTER_DEFAULT);
        this.err_code =
            await this.explorir_request_pressure_and_concentration_compensation();
        this.err_code = await this.explorir_set_output_data_all();
        this.err_code = await this.explorir_set_operation_mode(ExplorIRMode.EXPLORIR_MODE_DEFAULT);
        if (this.err_code == ExplorIRRetcode.EXPLORIR_SUCCESS) {
            return true;
        }
        else {
            return false;
        }
    }
    /*
          @brief Function to request filtered CO2 measurement from sensor
  
          @note This value needs to be multiplied by the appropriate scaling factor to derive the ppm value. See the
          ‘.’ command.
  
          @note Response: "Z #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_request_filtered_co2() {
        let msg = this.hex('Z\r\n');
        await this.explorirTX(msg, 3);
        await this.explorirReadData(); // read back the response and process the data
        return this.err_code;
    }
    /*
          @Function to get integer value of most recent filtered co2 measurement
          
          @ret 32-bit integer value
      */
    explorir_get_filtered_co2() {
        return this.current_filtered_co2;
    }
    /*
          @brief Function to request unfiltered CO2 measurement from sensor
  
          @note This value needs to be multiplied by the appropriate scaling factor to derive the ppm value. See the
          ‘.’ command.
  
          @note Response: "z #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_request_unfiltered_co2() {
        let msg = this.hex('z\r\n');
        await this.explorirTX(msg, 3);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @Function to get integer value of most recent unfiltered co2 measurement
          
          @ret 32-bit integer value
      */
    explorir_get_unfiltered_co2() {
        return this.current_unfiltered_co2;
    }
    /*
          @brief Function to request the scaling factor
          
          @note Required to convert raw CO2 measurement to ppm
  
          @note Response: ". #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_request_scaling_factor() {
        let msg = this.hex('.\r\n');
        await this.explorirTX(msg, 3);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to set operation mode
  
          @param[in] mode Mode type
  
          @note Response: "K #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_set_operation_mode(mode) {
        let msg = this.hex('K x\r\n');
        switch (mode) {
            case ExplorIRMode.EXPLORIR_MODE_STREAMING:
                msg[2] = '1'.charCodeAt(0);
                break;
            case ExplorIRMode.EXPLORIR_MODE_POLLING:
                msg[2] = '2'.charCodeAt(0);
                break;
            case ExplorIRMode.EXPLORIR_MODE_COMMAND:
                msg[2] = '0'.charCodeAt(0);
                break;
            default:
                this.err_code = ExplorIRRetcode.EXPLORIR_ERR_INVALID_MODE;
                return this.err_code;
        }
        await this.explorirTX(msg, 5);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to set value of digital filter
  
          @note Default is 16, Range is 0 - 65365, responds with new digital filter value
  
          @param[in] filter Value of digital filter
  
          @note Response: "A #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_set_digital_filter(filter) {
        if (filter > MAX_DIGITAL_FILTER || filter < MIN_DIGITAL_FILTER) {
            this.err_code = ExplorIRRetcode.EXPLORIR_ERR_INVALID_INPUT;
            return this.err_code;
        }
        let filterStr = filter.toString();
        let msgSize = 4 + filterStr.length;
        let msgStr = 'A ' + filterStr + '\r\n';
        let msg = this.hex(msgStr);
        await this.explorirTX(msg, msgSize);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to request the current value of the digital filter
  
          @note Response: "a #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_request_digital_filter() {
        let msg = this.hex('a\r\n');
        await this.explorirTX(msg, 3);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to set zero point using known reading
  
          @param[in] reported Reported gas concentration
  
          @param[in] actual Actual gas concentration
  
          @note Response: "F #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_set_zero_point_using_known_reading(reported, actual) {
        let reportedStr = reported.toString();
        let actualStr = actual.toString();
        let msgSize = 5 + reportedStr.length + actualStr.length;
        let msgStr = 'F ' + reportedStr + ' ' + actualStr + '\r\n';
        let msg = this.hex(msgStr);
        await this.explorirTX(msg, msgSize);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to set zero point assuming sensor is in fresh air
  
          @note Typically 400ppm CO2, but level can be set by user – see P commands.
  
          @note Response: "G #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_set_zero_point_in_fresh_air() {
        let msg = this.hex('G\r\n');
        await this.explorirTX(msg, 3);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to set zero point assuming sensor is in 0ppm CO2 such as nitrogen
  
          @note Response: "U #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_set_zero_point_in_nitrogen() {
        let msg = this.hex('U\r\n');
        await this.explorirTX(msg, 3);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to force a specific zero point
  
          @note Input value is scaled by CO2 value multiplier, see ‘.’ command.
  
          @param[in] zero_point Gas concentration to set zero point to
  
          @note Response: "u #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_set_zero_point_manually(zero) {
        let zeroStr = zero.toString();
        let msgSize = 4 + zeroStr.length;
        let msgStr = 'u ' + zeroStr + '\r\n';
        let msg = this.hex(msgStr);
        await this.explorirTX(msg, msgSize);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to set zero point assuming sensor is in known CO2 concentration
  
          @note Input value is scaled by CO2 value multiplier, see ‘.’ command.
  
          @param[in] co2_concentration Known CO2 concentration to set zero point with
  
          @note Response: "X #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_set_zero_point_using_known_co2(co2) {
        let co2Str = co2.toString();
        let msgSize = 4 + co2Str.length;
        let msgStr = 'X ' + co2Str + '\r\n';
        let msg = this.hex(msgStr);
        await this.explorirTX(msg, msgSize);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to set the value of CO2 in ppm used for auto-zeroing
  
          @note Input value is scaled by CO2 value multiplier, see ‘.’ command.
  
          @note The value is entered as a two-byte word, MSB first.
              MSB = Integer (Concentration/256)
              LSB = Concentration – (256*MSB)
  
          @param[in] co2_concentration CO2 value to set
  
          @note Response: "p 8 #\r\n"
                  "p 9 ##\r\n"
              Numbers mirror the input
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_set_co2_for_auto_zeroing(co2) {
        let msb = co2 & (0xff >> 8);
        let lsb = co2 & 0xff;
        let msbStr = msb.toString();
        let lsbStr = lsb.toString();
        let msgStr = 'P 8 ' + msbStr + '\r\n';
        let msgSize = msgStr.length;
        let msg = this.hex(msgStr);
        await this.explorirTX(msg, msgSize);
        msgStr = 'P 9 ' + lsbStr + '\r\n';
        msgSize = msgStr.length;
        msg = this.hex(msgStr);
        await this.explorirTX(msg, msgSize);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to set the value of CO2 in ppm for zero-point setting in fresh air
  
          @note Input value is scaled by CO2 value multiplier, see ‘.’ command.
  
          @note The value is entered as a two-byte word, MSB first.
              MSB = Integer (Concentration/256)
              LSB = Concentration – (256*MSB)
  
          @param[in] co2_concentration CO2 value to set
  
          @note Response: "p 10 #\r\n"
                  "p 11 ###\r\n"
              Numbers mirror the input
          
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_set_co2_for_zero_point_in_fresh_air(co2) {
        let msb = co2 & (0xff >> 8);
        let lsb = co2 & 0xff;
        let msbStr = msb.toString();
        let lsbStr = lsb.toString();
        let msgStr = 'P 10 ' + msbStr + '\r\n';
        let msgSize = msgStr.length;
        let msg = this.hex(msgStr);
        await this.explorirTX(msg, msgSize);
        msgStr = 'P 11 ' + lsbStr + '\r\n';
        msgSize = msgStr.length;
        msg = this.hex(msgStr);
        await this.explorirTX(msg, msgSize);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to set the 'Initial Interval' and 'Regular Interval' for auto-zeroing events
  
          @note Both the initial interval and regular interval are given in days. Both must be entered with a decimal
              point and one figure after the decimal point.
  
          @param[in] initial Initial interval in days
  
          @param[in] regular Regular interval in days
  
          @note Response: "@ #.# #.#\r\n"
              Numbers mirror the input
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_set_auto_zero_intervals(initial, regular) {
        if (initial < 0 || initial > 9 || regular < 0 || regular > 9) {
            this.err_code = ExplorIRRetcode.EXPLORIR_ERR_INVALID_INPUT;
            return this.err_code;
        }
        let initialStr = initial.toString();
        let regularStr = regular.toString();
        let msgStr = '@ ' + initialStr + '.0 ' + regularStr + '.0\r\n';
        let msgSize = msgStr.length;
        let msg = this.hex(msgStr);
        await this.explorirTX(msg, msgSize);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to disable auto-zeroing
  
          @note Response: "@ 0\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_disable_auto_zeroing() {
        let msg = this.hex('@ 0\r\n');
        await this.explorirTX(msg, 5);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to start an auto-zero immediately
  
          @note This is according to the datasheet, no more information is given
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_start_auto_zero() {
        let msg = this.hex('65222\r\n');
        await this.explorirTX(msg, 7);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to determine auto zero configuration
  
          @note Response: "@ #.# #.#\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_request_auto_zero_config() {
        let msg = this.hex('@\r\n');
        await this.explorirTX(msg, 3);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to set the 'Pressure and Concentration Compensation' value
  
          @param[in] value Pressure and Concetration Compensation value
  
          @note Response: "S ####\r\n"
              Numbers mirror the input
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_set_pressure_and_concentration_compensation(pcc) {
        let pccStr = pcc.toString();
        let msgSize = 4 + pccStr.length;
        let msgStr = 'S ' + pccStr + '\r\n';
        let msg = this.hex(msgStr);
        await this.explorirTX(msg, msgSize);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to request the 'Pressure and Concentration Compensation' value
  
          @note Response: "s ####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_request_pressure_and_concentration_compensation() {
        let msg = this.hex('s\r\n');
        await this.explorirTX(msg, 3);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to set the data types output by the sensor to filtered CO2
  
          @note Measurement Parameter Field Identifier Mask Value
              CO2 (Filtered)	      Z		    4
              CO2 (Unfiltered)	      z		    2
  
          @note Response: "M #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_set_output_data_filtered() {
        let msg = this.hex('M 00004\r\n');
        await this.explorirTX(msg, 9);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to set the data types output by the sensor to unfiltered CO2
  
          @note Measurement Parameter Field Identifier Mask Value
              CO2 (Filtered)	      Z		    4
              CO2 (Unfiltered)	      z		    2
  
          @note Response: "M #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_set_output_data_unfiltered() {
        let msg = this.hex('M 00002\r\n');
        await this.explorirTX(msg, 9);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to set the data types output by the sensor to filtered and unfiltered CO2
  
          @note Measurement Parameter Field Identifier Mask Value
              CO2 (Filtered)	      Z		    4
              CO2 (Unfiltered)	      z		    2
  
          @note Response: "M #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_set_output_data_all() {
        let msg = this.hex('M 00006\r\n');
        await this.explorirTX(msg, 9);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to request the number of output data fields
          
          @note Response: " Q #####\r\n"
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_request_output_data_fields() {
        let msg = this.hex('Q\r\n');
        await this.explorirTX(msg, 3);
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function to request sensor firmware version and serial number
  
          @note This command returns two lines split by a carriage return line feed and terminated by a carriage
              return line feed. This command requires that the sensor has been stopped (see ‘K’ command)
  
          @ret ExplorIr return code, either SUCCESS or failure
      */
    async explorir_request_sensor_info() {
        let msg = this.hex('Y\r\n');
        await this.explorirTX(msg, 3);
        // firmware version
        await this.explorirReadData();
        // serial number
        await this.explorirReadData();
        return this.err_code;
    }
    /*
          @brief Function for processing the response from the ExplorIr sensor
  
          @note The ExplorIr sensor responds in ASCII encoded messages
  
          @note Updates the current_x variables and err_code
      */
    processData(data = null) {
        if (data != null) {
            this.data = [];
            this.data = data;
        }
        if (this.data.length == 0) {
            this.err_code = ExplorIRRetcode.EXPLORIR_ERR_DATA_EMPTY;
            console.error(`ExplorIR data empty, make sure sensor is plugged into digital interface ${this.interfaceID}`);
            return [];
        }
        let i = 0;
        let exitLoop = false;
        while (this.data[i] != TERMINATE && !exitLoop && i < this.data.length) {
            switch (this.data[i]) {
                case SCALING_FACTOR:
                    let scaling_factor_data = [];
                    i += 2; // increment by 2 to move index to start of scaling factor
                    scaling_factor_data = this.data.slice(i, i + 5);
                    let scaling_factor_string = String.fromCharCode(...scaling_factor_data);
                    this.scaling_factor = parseInt(scaling_factor_string);
                    exitLoop = true;
                    break;
                case FILTERED_CO2_MEASUREMENT:
                    let filtered_co2_data = [];
                    i += 2;
                    filtered_co2_data = this.data.slice(i, i + 5);
                    let filtered_co2_string = String.fromCharCode(...filtered_co2_data);
                    this.current_filtered_co2 =
                        parseInt(filtered_co2_string) * this.scaling_factor;
                    i = 9;
                    break;
                case UNFILTERED_CO2_MEASUREMENT:
                    let unfiltered_co2_data = [];
                    i += 2;
                    unfiltered_co2_data = this.data.slice(i, i + 5);
                    let unfiltered_co2_string = String.fromCharCode(...unfiltered_co2_data);
                    this.current_unfiltered_co2 =
                        parseInt(unfiltered_co2_string) * this.scaling_factor;
                    exitLoop = true;
                    break;
                case OPERATION_MODE:
                    let operation_mode_data = [];
                    i += 2;
                    operation_mode_data = this.data.slice(i, i + 5);
                    let operation_mode_string = String.fromCharCode(...operation_mode_data);
                    this.current_mode = parseInt(operation_mode_string);
                    exitLoop = true;
                    break;
                case SET_DIGITAL_FILTER:
                case GET_DIGITAL_FILTER:
                    let digital_filter_data = [];
                    i += 2;
                    digital_filter_data = this.data.slice(i, i + 5);
                    let digital_filter_string = String.fromCharCode(...digital_filter_data);
                    this.digital_filter = parseInt(digital_filter_string);
                    exitLoop = true;
                    break;
                case FINE_TUNE_ZERO_POINT:
                case SET_ZERO_POINT_USING_FRESH_AIR:
                case SET_ZERO_POINT_USING_KNOWN_GAS:
                case SET_ZERO_POINT_USING_NITROGEN:
                case MANUALLY_SET_ZERO_POINT:
                    let zero_point_data = [];
                    i += 2;
                    zero_point_data = this.data.slice(i, i + 5);
                    let zero_point_string = String.fromCharCode(...zero_point_data);
                    this.zero_point = parseInt(zero_point_string);
                    exitLoop = true;
                    break;
                case SET_PRESSURE_AND_CONCENTRATION_COMPENSATION:
                case GET_PRESSURE_AND_CONCENTRATION_COMPENSATION:
                    let pcc_data = [];
                    i += 2;
                    pcc_data = this.data.slice(i, i + 5);
                    let pcc_string = String.fromCharCode(...pcc_data);
                    this.pressure_and_concentration_compensation = parseInt(pcc_string);
                    exitLoop = true;
                    break;
                case SET_TYPE_AND_NUM_OF_DATA_OUTPUTS:
                case GET_NUM_OF_OUTPUT_DATA_FIELDS:
                    i++;
                    break;
                case SENSOR_INFO:
                case SENSOR_SERIAL_NUM:
                    i++;
                    break;
                case SPACE:
                    i++;
                    break;
                case CARRIAGE_RETURN:
                    i++;
                    break;
                default:
                    if (this.data[i] >= 48 && this.data[i] <= 57) {
                        // Handle the case when this.data[i] is between '0' and '9'
                        i++;
                    }
                    else {
                        // Handle any other unrecognized command
                        this.err_code = ExplorIRRetcode.EXPLORIR_ERR_UNRECOGNIZED_COMMAND;
                        i++;
                    }
                    break;
            }
        }
        // reset data
        this.data = [];
        // return processed CO2 values
        return [
            ['Filtered CO2', this.current_filtered_co2, 'ppm'],
            ['Unfiltered CO2', this.current_unfiltered_co2, 'ppm'],
        ];
    }
}
export const ExplorIR = {
    name: 'ExplorIR CO2 Sensor',
    pwrEn: true,
    pwrMode: DigitalPowerMode.DIGITAL_RAIL_3V3,
    comm: CommProtocol.UART,
    ioEnable: true,
    commConfig: {
        address: 0x0000,
        baudRate: Baudrate.BAUDRATE_9600,
        pwmFreq: 0x0000,
        pwmDC: 0x0000,
        gpio: 0x0000,
    },
    handler: new ExplrorIRHandler(),
};
