import { ServiceConfigs, CalType, SensorType, } from './service.config.js';
import { OdysseySystemConfig } from './odyssey-system-config.js';
import { EXTERNAL_SENSOR_MODE } from './voyagerweather-command-codes.js';
export const portSensitiveDevices = ['odyssey'];
const thirdPartyWeather = 'openweather-all';
const thirdPartyAqi = 'aqicn';
const voyager2FrequencyConfigs = [
    { l: '60 sec/pt', i: 0xea600001, f: 1 / 60, sC: [0x7c, 0x00] },
    { l: '30 sec/pt', i: 0x75300001, f: 1 / 30, sC: [0x7c, 0x00] },
    { l: '15 sec/pt', i: 0x3a980001, f: 1 / 15, sC: [0x7c, 0x00] },
    { l: '10 sec/pt', i: 0x27100001, f: 1 / 10, sC: [0x7c, 0x00] },
    { l: '5 sec/pt', i: 0x13880001, f: 1 / 5, sC: [0x7c, 0x00] },
    { l: '1 pt/sec', i: 0x03e80001, f: 1, sC: [0x7c, 0x00] },
    { l: '5 pt/sec', i: 0x00c80001, f: 5, sC: [0x7c, 0x00] },
    { l: '10 pt/sec', i: 0x00c80002, f: 10, sC: [0x7c, 0x00] },
    { l: '20 pt/sec', i: 0x00960003, f: 20, sC: [0x7c, 0x00] },
    { l: '25 pt/sec', i: 0x00780003, f: 25, sC: [0x7c, 0x00] },
    { l: '50 pt/sec', i: 0x00780006, f: 50, sC: [0x7c, 0x00] },
    {
        l: '250 pt/sec',
        i: 0x00480012,
        f: 250,
        sC: [0x7c, 0x01],
        includeOnly: 'accelerationXYZ_v2',
    },
];
const thermo21FrequencyConfigs = [
    { l: '60 sec/pt', i: 0xea600001, f: 1 / 60 },
    { l: '30 sec/pt', i: 0x75300001, f: 1 / 30 },
    { l: '15 sec/pt', i: 0x3a980001, f: 1 / 15 },
    { l: '10 sec/pt', i: 0x27100001, f: 1 / 10 },
    { l: '5 sec/pt', i: 0x13880001, f: 1 / 5 },
    { l: '1 pt/sec', i: 0x03e80001, f: 1 },
    { l: '5 pt/sec', i: 0x00c80001, f: 5 },
    { l: '10 pt/sec', i: 0x00c80002, f: 10 },
    { l: '20 pt/sec', i: 0x00960003, f: 20 },
    { l: '25 pt/sec', i: 0x00780003, f: 25 },
    { l: '50 pt/sec', i: 0x00780006, f: 50 },
];
const gForceFrequencyConfigs = [
    { l: '50 pt/sec', i: 0x00780006, f: 50 },
];
const pl1FrequencyConfigs = [
    { l: '1 pt/sec', i: 100, f: 1 },
    { l: '10 pt/sec', i: 10, f: 10 },
    { l: '20 pt/sec', i: 5, f: 20 },
];
const thermoFrequencyConfigs = [
    { l: '60 sec/pt', i: 0xf0, f: 1 / 60 },
    { l: '30 sec/pt', i: EXTERNAL_SENSOR_MODE << 1, f: 1 / 30 },
    { l: '15 sec/pt', i: 0x3c, f: 1 / 15 },
    { l: '10 sec/pt', i: 0x28, f: 1 / 10 },
    { l: '5 sec/pt', i: 0x14, f: 1 / 5 },
    { l: '1 pt/sec', i: 0x04, f: 1 },
    { l: '4 pt/sec', i: 0x01, f: 4 },
];
const airFrequencyConfigs = [
    { l: '60 sec/pt', i: 0xae, f: 1 / 60 },
    { l: '30 sec/pt', i: 0xad, f: 1 / 30 },
    { l: '15 sec/pt', i: 0xac, f: 1 / 15 },
    { l: '10 sec/pt', i: 0xab, f: 1 / 10 },
    { l: '5 sec/pt', i: 0xaa, f: 1 / 5 },
    { l: '1 pt/sec', i: 1, f: 1 },
];
const onboardFrequencyConfigs = [
    { l: '1 pt/sec', i: 1, f: 1 },
    { l: '5 pt/sec', i: 5, f: 5 },
    { l: '10 pt/sec', i: 10, f: 10 },
    { l: '20 pt/sec', i: 20, f: 20 },
    { l: '25 pt/sec', i: 25, f: 25 },
    { l: '50 pt/sec', i: 50, f: 50 },
];
const voyagerFrequencyConfigs = [
    { l: '60 sec/pt', i: 0xae, f: 1 / 60 },
    { l: '30 sec/pt', i: 0xad, f: 1 / 30 },
    { l: '15 sec/pt', i: 0xac, f: 1 / 15 },
    { l: '10 sec/pt', i: 0xab, f: 1 / 10 },
    { l: '5 sec/pt', i: 0xaa, f: 1 / 5 },
    { l: '1 pt/sec', i: 1, f: 1 },
    { l: '5 pt/sec', i: 5, f: 5 },
    { l: '10 pt/sec', i: 10, f: 10 },
    { l: '20 pt/sec', i: 20, f: 20 },
    { l: '25 pt/sec', i: 25, f: 25 },
    { l: '50 pt/sec', i: 50, f: 50 },
];
const odysseyFrequencyConfigs = [
    { l: '60 sec/pt', i: 0xea600001, f: 1 / 60 },
    { l: '30 sec/pt', i: 0x75300001, f: 1 / 30 },
    { l: '15 sec/pt', i: 0x3a980001, f: 1 / 15 },
    { l: '10 sec/pt', i: 0x27100001, f: 1 / 10 },
    { l: '5 sec/pt', i: 0x13880001, f: 1 / 5 },
    { l: '1 pt/sec', i: 0x03e80001, f: 1 },
    { l: '5 pt/sec', i: 0x00c80001, f: 5 },
    { l: '10 pt/sec', i: 0x00c80002, f: 10 },
    { l: '20 pt/sec', i: 0x00960003, f: 20 },
    { l: '25 pt/sec', i: 0x00780003, f: 25 },
    { l: '50 pt/sec', i: 0x00780006, f: 50 },
];
const weather2UuidConfig = {
    service: '152fdea7-28a1-11ed-a261-0242ac120002',
    config: '152fdea9-28a1-11ed-a261-0242ac120002',
    data: '152fdea8-28a1-11ed-a261-0242ac120002',
};
const odysseyUuidConfig = {
    service: '919edeaa-4c45-11ee-be56-0242ac120002',
    config: '919edeab-4c45-11ee-be56-0242ac120002',
    data: '919edeac-4c45-11ee-be56-0242ac120002',
};
const thermo21UuidConfig = {
    service: 'e8c4dead-6303-11ee-8c99-0242ac120002',
    config: 'e8c4deae-6303-11ee-8c99-0242ac120002',
    data: 'e8c4deaf-6303-11ee-8c99-0242ac120002',
};
function onboardSensorIfPresent(sensorId, sensorConfig) {
    const w = typeof window === 'undefined' ? {} : window;
    if (w[sensorConfig.class]) {
        return { [sensorId]: sensorConfig };
    }
    else {
        return {};
    }
}
const supportsHyphenRegex = '[a-zA-Z0-9- ]+';
const doesNotSupportHyphenRegex = '[a-zA-Z0-9 ]+';
export const imuV2Calibrations = [
    {
        calibrationId: 'imuv2_tear',
        type: CalType.Click,
        factory: true,
    },
    {
        calibrationId: 'imuv2_iron',
        type: CalType.Click,
        factory: true,
    },
];
const imuOrientation = [
    {
        calibrationId: 'imu_compass',
        type: CalType.Compass,
        factory: true,
    },
];
export const DeviceConfigs = [
    {
        id: 'pl1',
        name: 'PL1',
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        sensorConfigs: {
            pl1acc: ServiceConfigs.pl1acc,
            pl1bar: ServiceConfigs.pl1bar,
            pl1gyr: ServiceConfigs.pl1gyr,
            pl1mag: ServiceConfigs.pl1mag,
        },
        thirdPartyConfigs: [],
        calibrationConfig: 'pl1bar',
        defaultFrequencyIndex: 1,
        defaultDataConfigs: ['pl1acc-acc'],
        frequencyConfigs: pl1FrequencyConfigs,
    },
    {
        id: 'weather',
        name: 'Weather',
        sensorConfigs: {
            bme280: ServiceConfigs.bme280,
            apds9301: ServiceConfigs.apds9301,
            ntc10k: ServiceConfigs.ntc10k,
        },
        thirdPartyConfigs: [thirdPartyWeather],
        memoryConfig: ServiceConfigs.uart,
        nameConfig: ServiceConfigs.voyname,
        regexNamingRule: doesNotSupportHyphenRegex,
        batteryConfig: ServiceConfigs.voybat,
        calibrationConfig: 'bme280',
        defaultFrequencyIndex: 5,
        defaultDataConfigs: [
            'bme280-pre',
            'bme280-tem_int',
            'bme280-hum',
            'apds9301-lig',
        ],
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        memoryStatusCommand: [0],
        renameCommand: [3],
        startRecordingToMemoryCommand: [5],
        lastMemoryCommandCommand: [12],
        stopRecordingToMemoryCommand: [7],
        downloadFromMemoryCommand: [8],
        clearMemoryCommand: [9],
        memoryDataFrequencyCommand: [10],
        namePrefix: 'PL ',
        frequencyConfigs: voyagerFrequencyConfigs,
    },
    {
        id: 'voyager',
        name: 'Voyager',
        sensorConfigs: {
            mpu9250: ServiceConfigs.mpu9250,
            bme280: ServiceConfigs.bme280,
            apds9301: ServiceConfigs.apds9301,
            ntc10k: ServiceConfigs.ntc10k,
            vl53l0x: ServiceConfigs.vl53l0x,
        },
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        thirdPartyConfigs: [thirdPartyWeather],
        memoryConfig: ServiceConfigs.uart,
        nameConfig: ServiceConfigs.voyname,
        regexNamingRule: doesNotSupportHyphenRegex,
        batteryConfig: ServiceConfigs.voybat,
        calibrationConfig: 'bme280',
        defaultFrequencyIndex: 7,
        defaultDataConfigs: ['mpu9250-acc'],
        memoryStatusCommand: [0],
        renameCommand: [3],
        startRecordingToMemoryCommand: [5],
        lastMemoryCommandCommand: [12],
        stopRecordingToMemoryCommand: [7],
        downloadFromMemoryCommand: [8],
        clearMemoryCommand: [9],
        memoryDataFrequencyCommand: [10],
        namePrefix: 'PL ',
        frequencyConfigs: voyagerFrequencyConfigs,
    },
    {
        name: 'Onboard',
        id: 'onboard',
        deviceInformationConfigs: null,
        sensorConfigs: {
            onboardmic: ServiceConfigs.onboardmic,
            ...onboardSensorIfPresent('onboardacc', ServiceConfigs.onboardacc),
            // ...onboardSensorIfPresent('onboardacclin', ServiceConfigs.onboardacclin),
            ...onboardSensorIfPresent('onboardgyr', ServiceConfigs.onboardgyr),
            ...onboardSensorIfPresent('onboardmag', ServiceConfigs.onboardmag),
            ...onboardSensorIfPresent('onboardlig', ServiceConfigs.onboardlig),
        },
        thirdPartyConfigs: [],
        defaultFrequencyIndex: 1,
        defaultDataConfigs: ['onboardmic-mic'],
        frequencyConfigs: onboardFrequencyConfigs,
    },
    {
        name: 'Air',
        id: 'air',
        sensorConfigs: {
            cozir: ServiceConfigs.cozir,
            plantower: ServiceConfigs.plantower,
            airBar: ServiceConfigs.airBar,
            airLig: ServiceConfigs.airLig,
            ozone: ServiceConfigs.ozone,
        },
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        thirdPartyConfigs: [thirdPartyWeather, thirdPartyAqi],
        calibrationConfig: 'airBar',
        defaultFrequencyIndex: 5,
        defaultDataConfigs: [
            'air-plantower',
            'air-cozir',
            'air-ozone',
            'air-aqi',
            'air-pre',
            'air-tem_int',
            'air-hum',
            'air-lig',
        ],
        frequencyConfigs: airFrequencyConfigs,
    },
    {
        name: 'Air',
        id: 'air0000',
        sensorConfigs: {
            cozir: ServiceConfigs.cozir0000,
            plantower: ServiceConfigs.plantower0000,
            airBar: ServiceConfigs.airBar0000,
            airLig: ServiceConfigs.airLig0000,
            ozone: ServiceConfigs.ozone0000,
        },
        thirdPartyConfigs: [thirdPartyWeather, thirdPartyAqi],
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        calibrationConfig: 'airBar',
        defaultFrequencyIndex: 5,
        defaultDataConfigs: [
            'air-plantower',
            'air-cozir',
            'air-ozone',
            'air-aqi',
            'air-pre',
            'air-tem_int',
            'air-hum',
            'air-lig',
        ],
        frequencyConfigs: airFrequencyConfigs,
    },
    {
        name: 'Air',
        id: 'air8e40',
        sensorConfigs: {
            cozir: ServiceConfigs.cozir8e40,
            plantower: ServiceConfigs.plantower8e40,
            airBar: ServiceConfigs.airBar8e40,
            airLig: ServiceConfigs.airLig8e40,
            ozone: ServiceConfigs.ozone8e40,
        },
        thirdPartyConfigs: [thirdPartyWeather, thirdPartyAqi],
        calibrationConfig: 'airBar',
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        defaultFrequencyIndex: 5,
        defaultDataConfigs: [
            'air-plantower',
            'air-cozir',
            'air-ozone',
            'air-aqi',
            'air-pre',
            'air-tem_int',
            'air-hum',
            'air-lig',
        ],
        frequencyConfigs: airFrequencyConfigs,
    },
    {
        name: 'Air',
        id: 'aircdea',
        sensorConfigs: {
            cozir: ServiceConfigs.cozircdea,
            plantower: ServiceConfigs.plantowercdea,
            airBar: ServiceConfigs.airBarcdea,
            airLig: ServiceConfigs.airLigcdea,
            ozone: ServiceConfigs.ozonecdea,
        },
        thirdPartyConfigs: [thirdPartyWeather, thirdPartyAqi],
        calibrationConfig: 'airBar',
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        memoryConfig: ServiceConfigs.uartcdea,
        defaultFrequencyIndex: 5,
        defaultDataConfigs: [
            'air-plantower',
            'air-cozir',
            'air-ozone',
            'air-aqi',
            'air-pre',
            'air-tem_int',
            'air-hum',
            'air-lig',
        ],
        memoryStatusCommand: [5, 0],
        renameCommand: [5, 3],
        startRecordingToMemoryCommand: [5, 5],
        lastMemoryCommandCommand: [5, 12],
        stopRecordingToMemoryCommand: [5, 7],
        downloadFromMemoryCommand: [5, 8],
        clearMemoryCommand: [5, 9],
        memoryDataFrequencyCommand: [5, 10],
        namePrefix: 'Air ',
        frequencyConfigs: airFrequencyConfigs,
    },
    {
        name: 'Air',
        id: 'aircdea2',
        sensorConfigs: {
            cozir: ServiceConfigs.cozircdea,
            plantower: ServiceConfigs.plantowercdea,
            airBar: ServiceConfigs.airBarcdea,
            airLig: ServiceConfigs.airLigcdea,
            ozone: ServiceConfigs.ozonecdea,
        },
        thirdPartyConfigs: [thirdPartyWeather, thirdPartyAqi],
        calibrationConfig: 'airBar',
        nameConfig: ServiceConfigs.airname,
        regexNamingRule: doesNotSupportHyphenRegex,
        batteryConfig: ServiceConfigs.voybat,
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        memoryConfig: ServiceConfigs.uartcdea,
        defaultFrequencyIndex: 5,
        defaultDataConfigs: [
            'air-plantower',
            'air-cozir',
            'air-ozone',
            'air-aqi',
            'air-pre',
            'air-tem_int',
            'air-hum',
            'air-lig',
        ],
        memoryStatusCommand: [5, 0],
        renameCommand: [5, 3],
        startRecordingToMemoryCommand: [5, 5],
        lastMemoryCommandCommand: [5, 12],
        stopRecordingToMemoryCommand: [5, 7],
        downloadFromMemoryCommand: [5, 8],
        clearMemoryCommand: [5, 9],
        memoryDataFrequencyCommand: [5, 10],
        namePrefix: 'PL ',
        frequencyConfigs: airFrequencyConfigs,
    },
    {
        name: 'Thermo',
        id: 'pl4',
        sensorConfigs: {
            pl4tempprobe: ServiceConfigs.pl4tempprobe,
        },
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        namePrefix: 'T ',
        thirdPartyConfigs: [],
        defaultFrequencyIndex: 5,
        defaultDataConfigs: ['pl4-pl4tem_pro'],
        renameCommand: [3],
        frequencyConfigs: thermoFrequencyConfigs,
    },
    {
        name: 'Thermo 2',
        id: 'pl42',
        sensorConfigs: {
            pl42tempprobe: ServiceConfigs.pl42tempprobe,
        },
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        namePrefix: 'T ',
        thirdPartyConfigs: [],
        defaultFrequencyIndex: 5,
        defaultDataConfigs: ['pl4-pl42tem_pro'],
        renameCommand: [3],
        frequencyConfigs: thermoFrequencyConfigs,
    },
    {
        name: 'G-Force',
        id: 'gforce',
        pollingPacketId: 0x99,
        crashModeFrequencyInstruction: 0x3c000003,
        crashModeSensors: ['displacement', 'velocity', 'accelerationXYZ'],
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        maxNumGraphs: 3,
        nameConfig: {
            id: 'powerlabname',
            config: '44a7dea2-9628-11eb-a8b3-0242ac120003',
            data: '44a7dea3-9628-11eb-a8b3-0242ac120003',
            service: '44a7dea1-9628-11eb-a8b3-0242ac120003',
        },
        regexNamingRule: supportsHyphenRegex,
        memoryConfig: {
            id: 'powerlabuart',
            config: '44a7dea2-9628-11eb-a8b3-0242ac120003',
            service: '44a7dea1-9628-11eb-a8b3-0242ac120003',
            data: '44a7dea3-9628-11eb-a8b3-0242ac120003',
        },
        crashModeConfig: {
            id: 'powerlabcrashmode',
            config: '44a7dea2-9628-11eb-a8b3-0242ac120003',
            service: '44a7dea1-9628-11eb-a8b3-0242ac120003',
            data: '44a7dea3-9628-11eb-a8b3-0242ac120003',
        },
        batteryConfig: {
            id: 'powerlabbattery',
            service: 0x180f,
            config: 0x2a19,
            data: 'NA',
        },
        sensorConfigs: {
            displacement: {
                id: 'displacement',
                service: '44a7dea1-9628-11eb-a8b3-0242ac120003',
                config: '44a7dea2-9628-11eb-a8b3-0242ac120003',
                data: '44a7dea3-9628-11eb-a8b3-0242ac120003',
                configValue: 0b00000000000000000000000000000001,
                valueLength: [4],
                cmdCode: 0x03,
                valType: { type: 'float', count: 1 },
                expectedVals: [0],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                type: SensorType.Encoder,
            },
            velocity: {
                id: 'velocity',
                service: '44a7dea1-9628-11eb-a8b3-0242ac120003',
                config: '44a7dea2-9628-11eb-a8b3-0242ac120003',
                data: '44a7dea3-9628-11eb-a8b3-0242ac120003',
                configValue: 0b00000000000000000000000000000010,
                valueLength: [4],
                cmdCode: 0x01,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                type: SensorType.Encoder,
            },
            /*
            direction: {
              id: 'direction',
              service: '44a7dea1-9628-11eb-a8b3-0242ac120003',
              config: '44a7dea2-9628-11eb-a8b3-0242ac120003',
              data: '44a7dea3-9628-11eb-a8b3-0242ac120003',
              configValue: 0b00000000000000000000000000000100,
              valueLength: 1,
              ...gForceFrequencyConfig,
              cmdCode: 0x02,
              valType: {type: 'int8', count: 1},
              expectedVals: [0x77],
              toleranceRange: 0,
              defaultVal: 0x77,
              type: SensorType.Encoder,
            },
            */
            acceleration: {
                id: 'acceleration',
                service: '44a7dea1-9628-11eb-a8b3-0242ac120003',
                config: '44a7dea2-9628-11eb-a8b3-0242ac120003',
                data: '44a7dea3-9628-11eb-a8b3-0242ac120003',
                configValue: 0b00000000000000000000000000001000,
                valueLength: [4],
                cmdCode: 0x04,
                valType: { type: 'float', count: 3 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                type: SensorType.Encoder,
            },
            accelerationXYZ: {
                id: 'accelerationXYZ',
                service: '44a7dea1-9628-11eb-a8b3-0242ac120003',
                config: '44a7dea2-9628-11eb-a8b3-0242ac120003',
                data: '44a7dea3-9628-11eb-a8b3-0242ac120003',
                configValue: 0b00000000000000000000000001110000,
                valueLength: [4, 4, 4],
                cmdCode: 0x05,
                valType: { type: 'float', count: 3 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            gyroscopeXYZ: {
                id: 'gyroscopeXYZ',
                service: '44a7dea1-9628-11eb-a8b3-0242ac120003',
                config: '44a7dea2-9628-11eb-a8b3-0242ac120003',
                data: '44a7dea3-9628-11eb-a8b3-0242ac120003',
                configValue: 0b00000000000000000000001110000000,
                valueLength: [4, 4, 4],
                cmdCode: 0x06,
                valType: { type: 'float', count: 3 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            magnetometerXYZ: {
                id: 'magnetometerXYZ',
                service: '44a7dea1-9628-11eb-a8b3-0242ac120003',
                config: '44a7dea2-9628-11eb-a8b3-0242ac120003',
                data: '44a7dea3-9628-11eb-a8b3-0242ac120003',
                configValue: 0b00000000000000000001110000000000,
                valueLength: [4, 4, 4],
                cmdCode: 0x07,
                valType: { type: 'float', count: 3 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            heading: {
                id: 'heading',
                service: '44a7dea1-9628-11eb-a8b3-0242ac120003',
                config: '44a7dea2-9628-11eb-a8b3-0242ac120003',
                data: '44a7dea3-9628-11eb-a8b3-0242ac120003',
                configValue: 0b00000000000000000010000000000000,
                valueLength: [4],
                cmdCode: 0x08,
                valType: { type: 'float', count: 3 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                calibrations: imuOrientation,
            },
            pitch: {
                id: 'pitch',
                service: '44a7dea1-9628-11eb-a8b3-0242ac120003',
                config: '44a7dea2-9628-11eb-a8b3-0242ac120003',
                data: '44a7dea3-9628-11eb-a8b3-0242ac120003',
                valueLength: [4],
                configValue: 0b00000000000000000100000000000000,
                cmdCode: 0x08,
                valType: { type: 'float', count: 3 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                calibrations: imuOrientation,
            },
            roll: {
                id: 'roll',
                service: '44a7dea1-9628-11eb-a8b3-0242ac120003',
                config: '44a7dea2-9628-11eb-a8b3-0242ac120003',
                data: '44a7dea3-9628-11eb-a8b3-0242ac120003',
                valueLength: [4],
                configValue: 0b00000000000000001000000000000000,
                cmdCode: 0x08,
                valType: { type: 'float', count: 3 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                calibrations: imuOrientation,
            },
            quaternion: {
                id: 'quaternion',
                service: '44a7dea1-9628-11eb-a8b3-0242ac120003',
                config: '44a7dea2-9628-11eb-a8b3-0242ac120003',
                data: '44a7dea3-9628-11eb-a8b3-0242ac120003',
                configValue: 0b00000000000000010000000000000000,
                valueLength: [16],
                cmdCode: 0x09,
                valType: { type: 'float', count: 3 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                calibrations: imuOrientation,
            },
        },
        calibrationRoutines: {
            compass: {
                sensor: {
                    id: 'gyroscopeXYZ',
                    cmdCode: 0x05,
                    configValue: 0b00000000000000000000001110000000,
                    valType: { type: 'float', count: 3 },
                    expectedVals: [0.0, 0.0, 0.98],
                    toleranceRange: 0.05,
                    defaultVal: 99.99,
                },
                id: 'Compass (IMU)',
                description: 'Reads and sets current xyz values as an offset for measured values.',
                calCmd: 0x24,
                code: 0x01, // tares the xyz values of the IMU
            },
        },
        namePrefix: 'PL ',
        thirdPartyConfigs: [],
        defaultFrequencyIndex: 0,
        defaultDataConfigs: [
            // 'imuqua',
            'encdis',
            'encvel',
            'imuacc',
        ],
        renameCommand: [0x50, 0x03],
        shutdownCommand: [0x46, 0xe3],
        restartCommand: [0x44, 0xd9],
        enableSaveCommand: [0x40, 0xc1],
        saveCommand: [0x42, 0x09],
        memoryStatusCommand: [0x23],
        startRecordingToMemoryCommand: [0x6a, 0x01],
        stopRecordingToMemoryCommand: [0x6a, 0x00],
        downloadFromMemoryCommand: [0x4c, 0x76],
        clearMemoryCommand: [0x4c, 0x97],
        frequencyConfigs: gForceFrequencyConfigs,
        maxNumSensorBytesInChunk: 237,
        maxNumBLEPayloadBytes: 244,
        flashPacketId: 0x96,
    },
    // Voyager Pro
    {
        name: 'Voyager 2',
        id: 'voyagerpro',
        pollingPacketId: 0x99,
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        maxNumGraphs: 3,
        nameConfig: {
            id: 'powerlabname',
            service: '152fdea4-28a1-11ed-a261-0242ac120002',
            config: '152fdea5-28a1-11ed-a261-0242ac120002',
            data: '152fdea6-28a1-11ed-a261-0242ac120002',
        },
        regexNamingRule: supportsHyphenRegex,
        memoryConfig: {
            id: 'powerlabuart',
            config: '152fdea5-28a1-11ed-a261-0242ac120002',
            service: '152fdea4-28a1-11ed-a261-0242ac120002',
            data: '152fdea6-28a1-11ed-a261-0242ac120002',
        },
        dfuConfig: {
            id: 'powerlabdfu',
            service: 0xfe59,
            config: 0x2902,
            data: 'NA',
        },
        batteryConfig: {
            id: 'powerlabbattery',
            service: 0x180f,
            config: 0x2a19,
            data: 'NA',
        },
        sensorConfigs: {
            accelerationXYZ_v2: {
                id: 'accelerationXYZ_v2',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000000000000000001110000,
                valueLength: [4, 4, 4],
                cmdCode: 0x05,
                valType: { type: 'float', count: 3 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                calibrations: imuV2Calibrations,
            },
            gyroscopeXYZ_v2: {
                id: 'gyroscopeXYZ_v2',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000000000000001110000000,
                valueLength: [4, 4, 4],
                cmdCode: 0x06,
                valType: { type: 'float', count: 3 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                calibrations: imuV2Calibrations,
            },
            magnetometerXYZ: {
                id: 'magnetometerXYZ',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000000000001110000000000,
                valueLength: [4, 4, 4],
                cmdCode: 0x07,
                valType: { type: 'float', count: 3 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            heading_dev: {
                id: 'heading_dev',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000000000010000000000000,
                valueLength: [4],
                cmdCode: 0x08,
                valType: { type: 'float', count: 3 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                calibrations: imuOrientation,
            },
            pitch_dev: {
                id: 'pitch_dev',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000000000100000000000000,
                valueLength: [4],
                cmdCode: 0x08,
                valType: { type: 'float', count: 3 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                calibrations: imuOrientation,
            },
            roll_dev: {
                id: 'roll_dev',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000000001000000000000000,
                valueLength: [4],
                cmdCode: 0x08,
                valType: { type: 'float', count: 3 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                calibrations: imuOrientation,
            },
            quaternion: {
                id: 'quaternion',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000000010000000000000000,
                valueLength: [16],
                cmdCode: 0x09,
                valType: { type: 'float', count: 3 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                calibrations: imuOrientation,
            },
            rangefinder: {
                id: 'rangefinder',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000001000000000000000000000000,
                valueLength: [4],
                cmdCode: 0x41,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                calibrations: [
                    {
                        type: CalType.Click,
                        factory: true,
                        calibrationId: 'a54_1',
                    },
                    {
                        type: CalType.Click,
                        factory: true,
                        calibrationId: 'a54_2',
                    },
                    {
                        type: CalType.Click,
                        factory: true,
                        calibrationId: 'a54_3',
                    },
                    {
                        type: CalType.Click,
                        factory: true,
                        calibrationId: 'a54_4',
                    },
                    {
                        type: CalType.Click,
                        factory: true,
                        calibrationId: 'a54_5',
                    },
                    {
                        type: CalType.Click,
                        factory: true,
                        calibrationId: 'a54_6',
                    },
                    {
                        calibrationId: 'a54_7',
                        type: CalType.Click,
                        factory: true,
                    },
                ],
            },
            rangefinderdisplacement: {
                id: 'rangefinderdisplacement',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000000000000000000000001,
                valueLength: [4],
            },
            rangefindervelocity: {
                id: 'rangefindervelocity',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000000000000000000000010,
                valueLength: [4],
            },
            rangefinderacceleration: {
                id: 'rangefinderacceleration',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000000000000000000001000,
                valueLength: [4],
            },
            extsensortemperature: {
                id: 'extsensortemperature',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000000100000000000000000,
                valueLength: [4],
                secondaryConfig: { command: [EXTERNAL_SENSOR_MODE << 1, 0x03] },
                exclude: [
                    'extsensorvoltage',
                    'extsensorresistance',
                    'extsensorcurrent',
                ],
            },
            extsensorvoltage: {
                id: 'extsensorvoltage',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000000100000000000000000,
                valueLength: [4],
                secondaryConfig: { command: [EXTERNAL_SENSOR_MODE << 1, 0x00] },
                exclude: [
                    'extsensortemperature',
                    'extsensorresistance',
                    'extsensorcurrent',
                ],
                cmdCode: 0x0a,
                valType: { type: 'float', count: 1 },
                expectedVals: [3.0],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            extsensorresistance: {
                id: 'extsensorresistance',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000000100000000000000000,
                valueLength: [4],
                secondaryConfig: { command: [EXTERNAL_SENSOR_MODE << 1, 0x01] },
                exclude: [
                    'extsensorvoltage',
                    'extsensortemperature',
                    'extsensorcurrent',
                ],
            },
            extsensorcurrent: {
                id: 'extsensorcurrent',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000000100000000000000000,
                valueLength: [4],
                secondaryConfig: { command: [EXTERNAL_SENSOR_MODE << 1, 0x02] },
                exclude: [
                    'extsensorvoltage',
                    'extsensortemperature',
                    'extsensorresistance',
                ],
            },
            temperature: {
                id: 'temperature',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000001000000000000000000,
                valueLength: [4],
                cmdCode: 0x0b,
                valType: { type: 'float', count: 1 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            pressure: {
                id: 'pressure',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000010000000000000000000,
                valueLength: [4],
                cmdCode: 0x0c,
                valType: { type: 'float', count: 1 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            humidity: {
                id: 'humidity',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000000100000000000000000000,
                valueLength: [4],
                cmdCode: 0x0d,
                valType: { type: 'float', count: 1 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            altitude: {
                id: 'altitude',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000001000000000000000000000,
                valueLength: [4],
                cmdCode: 0x0e,
                valType: { type: 'float', count: 1 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                calibrations: [
                    {
                        calibrationId: 'plalt_input',
                        type: CalType.Input,
                        factory: true,
                    },
                ],
            },
            dewpoint: {
                id: 'dewpoint',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000010000000000000000000000,
                valueLength: [4],
                cmdCode: 0x0f,
                valType: { type: 'float', count: 1 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            heatindex: {
                id: 'heatindex',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000000100000000000000000000000,
                valueLength: [4],
                cmdCode: 0x40,
                valType: { type: 'float', count: 1 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            uvlight_dev: {
                id: 'uvlight_dev',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000010000000000000000000000000,
                valueLength: [4],
            },
            ambientlight: {
                id: 'ambientlight',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00000100000000000000000000000000,
                valueLength: [4],
                cmdCode: 0x43,
                valType: { type: 'float', count: 1 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            irlight_dev: {
                id: 'irlight_dev',
                service: '152fdea4-28a1-11ed-a261-0242ac120002',
                config: '152fdea5-28a1-11ed-a261-0242ac120002',
                data: '152fdea6-28a1-11ed-a261-0242ac120002',
                configValue: 0b00001000000000000000000000000000,
                valueLength: [4],
            },
        },
        calibrationRoutines: {
            compass: {
                id: 'Compass (IMU)',
                sensor: {
                    id: 'accelerationXYZ_v2',
                    service: '152fdea4-28a1-11ed-a261-0242ac120002',
                    config: '152fdea5-28a1-11ed-a261-0242ac120002',
                    data: '152fdea6-28a1-11ed-a261-0242ac120002',
                    configValue: 0b00000000000000000000000001110000,
                    valueLength: [4, 4, 4],
                    cmdCode: 0x05,
                    valType: { type: 'float', count: 3 },
                    expectedVals: [],
                    toleranceRange: 0.005,
                    defaultVal: 99.99,
                },
                description: 'Reads and sets current xyz values as an offset for measured values.',
                calCmd: 0x24,
                code: 0x01, // tares the xyz values of the IMU
            },
            rangefinder: {
                id: 'Rangefinder',
                sensor: {
                    id: 'rangefinder',
                    service: '152fdea4-28a1-11ed-a261-0242ac120002',
                    config: '152fdea5-28a1-11ed-a261-0242ac120002',
                    data: '152fdea6-28a1-11ed-a261-0242ac120002',
                    configValue: 0b00000001000000000000000000000000,
                    valueLength: [4],
                    cmdCode: 0x41,
                    valType: { type: 'float', count: 1 },
                    expectedVals: [99.99],
                    toleranceRange: 0.005,
                    defaultVal: 99.99,
                },
                description: 'Runs the rangefinder auto-cal routine',
                calCmd: 0x2a,
                code: 0x07, // Run the auto calibration routine
            },
        },
        namePrefix: 'PL ',
        thirdPartyConfigs: [],
        defaultFrequencyIndex: 8,
        defaultDataConfigs: ['imuacc_v2'],
        renameCommand: [0x50, 0x03],
        shutdownCommand: [0x46, 0xe3],
        restartCommand: [0x44, 0xd9],
        enableSaveCommand: [0x40, 0xc1],
        saveCommand: [0x42, 0x09],
        memoryStatusCommand: [0x23],
        startRecordingToMemoryCommand: [0x6a, 0x01],
        stopRecordingToMemoryCommand: [0x6a, 0x00],
        downloadFromMemoryCommand: [0x4c, 0x76],
        clearMemoryCommand: [0x4c, 0x97],
        frequencyConfigs: voyager2FrequencyConfigs,
        maxNumSensorBytesInChunk: 237,
        maxNumBLEPayloadBytes: 244,
        flashPacketId: 0x96,
    },
    {
        name: 'Weather 2',
        id: 'weatherpro',
        pollingPacketId: 0x99,
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        // TODO change this for prod
        maxNumGraphs: 4,
        nameConfig: {
            // '152fdea9-28a1-11ed-a261-0242ac120002',
            id: 'powerlabname',
            ...weather2UuidConfig,
        },
        regexNamingRule: supportsHyphenRegex,
        memoryConfig: {
            id: 'powerlabuart',
            ...weather2UuidConfig,
        },
        dfuConfig: {
            id: 'powerlabdfu',
            service: 0xfe59,
            config: 0x2902,
            data: 'NA',
        },
        batteryConfig: {
            id: 'powerlabbattery',
            service: 0x180f,
            config: 0x2a19,
            data: 'NA',
        },
        sensorConfigs: {
            extsensortemperature: {
                id: 'extsensortemperature',
                ...weather2UuidConfig,
                configValue: 0b00000000000000100000000000000000,
                valueLength: [4],
                secondaryConfig: { command: [EXTERNAL_SENSOR_MODE << 1, 0x03] },
                exclude: [
                    'extsensorvoltage',
                    'extsensorresistance',
                    'extsensorcurrent',
                ],
            },
            extsensorvoltage: {
                id: 'extsensorvoltage',
                ...weather2UuidConfig,
                configValue: 0b00000000000000100000000000000000,
                valueLength: [4],
                secondaryConfig: { command: [EXTERNAL_SENSOR_MODE << 1, 0x00] },
                exclude: [
                    'extsensortemperature',
                    'extsensorresistance',
                    'extsensorcurrent',
                ],
                cmdCode: 0x0a,
                valType: { type: 'float', count: 1 },
                expectedVals: [3.0],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            extsensorresistance: {
                id: 'extsensorresistance',
                ...weather2UuidConfig,
                configValue: 0b00000000000000100000000000000000,
                valueLength: [4],
                secondaryConfig: { command: [EXTERNAL_SENSOR_MODE << 1, 0x01] },
                exclude: [
                    'extsensorvoltage',
                    'extsensortemperature',
                    'extsensorcurrent',
                ],
            },
            extsensorcurrent: {
                id: 'extsensorcurrent',
                ...weather2UuidConfig,
                configValue: 0b00000000000000100000000000000000,
                valueLength: [4],
                secondaryConfig: { command: [EXTERNAL_SENSOR_MODE << 1, 0x02] },
                exclude: [
                    'extsensorvoltage',
                    'extsensortemperature',
                    'extsensorresistance',
                ],
            },
            temperature: {
                id: 'temperature',
                ...weather2UuidConfig,
                configValue: 0b00000000000001000000000000000000,
                valueLength: [4],
                cmdCode: 0x0b,
                valType: { type: 'float', count: 1 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            pressure: {
                id: 'pressure',
                ...weather2UuidConfig,
                configValue: 0b00000000000010000000000000000000,
                valueLength: [4],
                cmdCode: 0x0c,
                valType: { type: 'float', count: 1 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            humidity: {
                id: 'humidity',
                ...weather2UuidConfig,
                configValue: 0b00000000000100000000000000000000,
                valueLength: [4],
                cmdCode: 0x0d,
                valType: { type: 'float', count: 1 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            altitude: {
                id: 'altitude',
                ...weather2UuidConfig,
                configValue: 0b00000000001000000000000000000000,
                valueLength: [4],
                cmdCode: 0x0e,
                valType: { type: 'float', count: 1 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            dewpoint: {
                id: 'dewpoint',
                ...weather2UuidConfig,
                configValue: 0b00000000010000000000000000000000,
                valueLength: [4],
                cmdCode: 0x0f,
                valType: { type: 'float', count: 1 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            heatindex: {
                id: 'heatindex',
                ...weather2UuidConfig,
                configValue: 0b00000000100000000000000000000000,
                valueLength: [4],
                cmdCode: 0x40,
                valType: { type: 'float', count: 1 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            uvlight_dev: {
                id: 'uvlight_dev',
                ...weather2UuidConfig,
                configValue: 0b00000010000000000000000000000000,
                valueLength: [4],
            },
            ambientlight: {
                id: 'ambientlight',
                ...weather2UuidConfig,
                configValue: 0b00000100000000000000000000000000,
                valueLength: [4],
                cmdCode: 0x43,
                valType: { type: 'float', count: 1 },
                expectedVals: [],
                toleranceRange: 0.005,
                defaultVal: 99.99,
            },
            irlight_dev: {
                id: 'irlight_dev',
                ...weather2UuidConfig,
                configValue: 0b00001000000000000000000000000000,
                valueLength: [4],
            },
        },
        namePrefix: 'PL ',
        thirdPartyConfigs: [],
        defaultFrequencyIndex: 5,
        defaultDataConfigs: ['pltem'],
        renameCommand: [0x50, 0x03],
        shutdownCommand: [0x46, 0xe3],
        restartCommand: [0x44, 0xd9],
        enableSaveCommand: [0x40, 0xc1],
        saveCommand: [0x42, 0x09],
        memoryStatusCommand: [0x23],
        startRecordingToMemoryCommand: [0x6a, 0x01],
        stopRecordingToMemoryCommand: [0x6a, 0x00],
        downloadFromMemoryCommand: [0x4c, 0x76],
        clearMemoryCommand: [0x4c, 0x97],
        frequencyConfigs: voyager2FrequencyConfigs,
        maxNumSensorBytesInChunk: 237,
        maxNumBLEPayloadBytes: 244,
        flashPacketId: 0x96,
    },
    {
        name: 'Odyssey',
        id: 'odyssey',
        pollingPacketId: 0x89,
        flashPacketId: 0x86,
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        // TODO change this for prod
        maxNumGraphs: 4,
        nameConfig: {
            id: 'powerlabname',
            ...odysseyUuidConfig,
        },
        regexNamingRule: supportsHyphenRegex,
        memoryConfig: {
            id: 'powerlabuart',
            ...odysseyUuidConfig,
        },
        dfuConfig: {
            id: 'powerlabdfu',
            service: 0xfe59,
            config: 0x2902,
            data: 'NA',
        },
        batteryConfig: {
            id: 'powerlabbattery',
            service: 0x180f,
            config: 0x2a19,
            data: 'NA',
        },
        sensorConfigs: {
            ph: {
                id: 'ph',
                ...odysseyUuidConfig,
                configValue: 0b00000000000000000000000000000001,
                valueLength: [4],
                cmdCode: 0x50,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                unit: 'pH',
                decimalPlaces: 3,
                setupInstruction: 'device-config.phInstruction',
                calibrations: [
                    {
                        type: CalType.Probe,
                        calibrationId: 'ph_probe_1',
                    },
                    {
                        type: CalType.Auxiliary,
                        calibrationId: 'ph_probe_2',
                    },
                    {
                        type: CalType.Auxiliary,
                        calibrationId: 'ph_probe_3',
                    },
                    {
                        type: CalType.Click,
                        calibrationId: 'ph_click',
                    },
                ],
            },
            orp: {
                id: 'orp',
                ...odysseyUuidConfig,
                configValue: 0b00000000000000000000000000000010,
                valueLength: [4],
                cmdCode: 0x51,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                unit: 'mV',
                decimalPlaces: 3,
                setupInstruction: 'device-config.orpInstruction',
            },
            dissolvedoxygen: {
                id: 'dissolvedoxygen',
                ...odysseyUuidConfig,
                configValue: 0b00000000000000000000000000000100,
                valueLength: [4],
                cmdCode: 0x52,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                unit: 'mg/L',
                decimalPlaces: 3,
                setupInstruction: 'device-config.dissolvedoxygenInstruction',
            },
            voltage1: {
                id: 'voltage1',
                ...odysseyUuidConfig,
                configValue: 0b00000000000000000000000000001000,
                valueLength: [4],
                cmdCode: 0x53,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                unit: 'V',
                decimalPlaces: 3,
                setupInstruction: 'device-config.virt1Instruction',
                secondaryConfig: {
                    system: {
                        registerIdx: 3,
                        enableBitIdx: 6,
                    },
                },
                exclude: ['resistance1', 'temperature1'],
            },
            current1: {
                id: 'current1',
                ...odysseyUuidConfig,
                configValue: 0b00000000000000000000000000010000,
                valueLength: [4],
                cmdCode: 0x54,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                unit: 'A',
                decimalPlaces: 6,
                setupInstruction: 'device-config.virt1Instruction',
                secondaryConfig: {
                    system: {
                        registerIdx: 3,
                        enableBitIdx: 8,
                    },
                },
                exclude: ['temperature1'],
            },
            resistance1: {
                id: 'resistance1',
                ...odysseyUuidConfig,
                configValue: 0b00000000000000000000000000100000,
                valueLength: [4],
                cmdCode: 0x55,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                unit: 'Ω',
                decimalPlaces: 3,
                setupInstruction: 'device-config.virt1Instruction',
                secondaryConfig: {
                    system: {
                        registerIdx: 3,
                        enableBitIdx: 1,
                    },
                },
                exclude: ['voltage1', 'temperature1'],
            },
            temperature1: {
                id: 'temperature1',
                ...odysseyUuidConfig,
                configValue: 0b00000000000000000000000001000000,
                valueLength: [4],
                cmdCode: 0x56,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                unit: '°C',
                decimalPlaces: 3,
                setupInstruction: 'device-config.temperature1Instruction',
                secondaryConfig: {
                    system: {
                        registerIdx: 3,
                        enableBitIdx: 4,
                    },
                },
                exclude: ['voltage1', 'resistance1', 'current1'],
            },
            voltage2: {
                id: 'voltage2',
                ...odysseyUuidConfig,
                configValue: 0b00000000000000000000000010000000,
                valueLength: [4],
                cmdCode: 0x57,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                unit: 'V',
                decimalPlaces: 3,
                setupInstruction: 'device-config.virt2Instruction',
                secondaryConfig: {
                    system: {
                        registerIdx: 4,
                        enableBitIdx: 6,
                    },
                },
                exclude: ['temperature2', 'resistance2'],
            },
            current2: {
                id: 'current2',
                ...odysseyUuidConfig,
                configValue: 0b00000000000000000000000100000000,
                valueLength: [4],
                cmdCode: 0x58,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                unit: 'A',
                decimalPlaces: 6,
                setupInstruction: 'device-config.virt2Instruction',
                secondaryConfig: {
                    system: {
                        registerIdx: 4,
                        enableBitIdx: 8,
                    },
                },
                exclude: ['temperature2'],
            },
            resistance2: {
                id: 'resistance2',
                ...odysseyUuidConfig,
                configValue: 0b00000000000000000000001000000000,
                valueLength: [4],
                cmdCode: 0x59,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                unit: 'Ω',
                decimalPlaces: 3,
                setupInstruction: 'device-config.virt2Instruction',
                secondaryConfig: {
                    system: {
                        registerIdx: 4,
                        enableBitIdx: 1,
                    },
                },
                exclude: ['temperature2', 'voltage2'],
            },
            temperature2: {
                id: 'temperature2',
                ...odysseyUuidConfig,
                configValue: 0b00000000000000000000010000000000,
                valueLength: [4],
                cmdCode: 0x5a,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                unit: '°C',
                decimalPlaces: 3,
                setupInstruction: 'device-config.temperature2Instruction',
                secondaryConfig: {
                    system: {
                        registerIdx: 4,
                        enableBitIdx: 4,
                    },
                },
                exclude: ['resistance2', 'voltage2', 'current2'],
            },
            oxygen1: {
                id: 'oxygen1',
                ...odysseyUuidConfig,
                valueLength: [16],
                configValue: 0b00000000000000000000100000000000,
                cmdCode: 0x5b,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                decimalPlaces: 3,
                setupInstruction: 'device-config.digital1Instruction',
                type: SensorType.Digital,
                exclude: ['oxygen2'],
                secondaryConfig: {
                    disIdx: 2,
                },
            },
            oxygen2: {
                id: 'oxygen2',
                ...odysseyUuidConfig,
                configValue: 0b00000000000000000001000000000000,
                cmdCode: 0x5c,
                valueLength: [16],
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                decimalPlaces: 3,
                setupInstruction: 'device-config.digital2Instruction',
                type: SensorType.Digital,
                exclude: ['oxygen1'],
            },
            electricalconductivity: {
                id: 'electricalconductivity',
                ...odysseyUuidConfig,
                configValue: 0b00000000000000000010000000000000,
                valueLength: [4],
                cmdCode: 0x5d,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                incorrectSetupIndicator: 0,
                unit: 'μS/cm',
                decimalPlaces: 3,
                setupInstruction: 'device-config.electricalconductivityInstruction',
            },
            absolutepressure: {
                id: 'absolutepressure',
                ...odysseyUuidConfig,
                configValue: 0b00000000000000000100000000000000,
                valueLength: [4],
                cmdCode: 0x5e,
                valType: { type: 'float', count: 1 },
                expectedVals: [99.99],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                unit: 'kPa',
                decimalPlaces: 3,
            },
        },
        namePrefix: 'PL ',
        thirdPartyConfigs: [],
        defaultFrequencyIndex: 5,
        defaultDataConfigs: ['odypre'],
        renameCommand: [0x50, 0x03],
        shutdownCommand: [0x46, 0xe3],
        restartCommand: [0x44, 0xd9],
        enableSaveCommand: [0x40, 0xc1],
        saveCommand: [0x42, 0x09],
        memoryStatusCommand: [0x23],
        startRecordingToMemoryCommand: [0x6a, 0x01],
        stopRecordingToMemoryCommand: [0x6a, 0x00],
        downloadFromMemoryCommand: [0x4c, 0x76],
        clearMemoryCommand: [0x4c, 0x97],
        frequencyConfigs: odysseyFrequencyConfigs,
        maxNumSensorBytesInChunk: 237,
        maxNumBLEPayloadBytes: 244,
        systemConfig: new OdysseySystemConfig(),
    },
    {
        name: 'Thermo 2.1',
        id: 'pl43',
        pollingPacketId: 0x99,
        deviceInformationConfigs: {
            firmwareinfo: ServiceConfigs.firmwareinfo,
            hardwareinfo: ServiceConfigs.hardwareinfo,
            manufacturerinfo: ServiceConfigs.manufacturerinfo,
        },
        maxNumGraphs: 1,
        nameConfig: {
            id: 'powerlabname',
            ...thermo21UuidConfig,
        },
        regexNamingRule: supportsHyphenRegex,
        dfuConfig: {
            id: 'powerlabdfu',
            service: 0xfe59,
            config: 0x2902,
            data: 'NA',
        },
        batteryConfig: {
            id: 'powerlabbattery',
            service: 0x180f,
            config: 0x2a19,
            data: 'NA',
        },
        sensorConfigs: {
            voltage12: {
                id: 'voltage12',
                ...thermo21UuidConfig,
                configValue: 0b00010000000000100000000000000000,
                secondaryConfig: { command: [EXTERNAL_SENSOR_MODE << 1, 0x00] },
                valueLength: [4, 4],
            },
            resistance12: {
                id: 'resistance12',
                ...thermo21UuidConfig,
                configValue: 0b00010000000000100000000000000000,
                secondaryConfig: { command: [EXTERNAL_SENSOR_MODE << 1, 0x11] },
                valueLength: [4, 4],
            },
            current12: {
                id: 'current12',
                ...thermo21UuidConfig,
                configValue: 0b00010000000000100000000000000000,
                secondaryConfig: { command: [EXTERNAL_SENSOR_MODE << 1, 0x22] },
                valueLength: [4, 4],
            },
            temperature12: {
                id: 'temperature12',
                ...thermo21UuidConfig,
                configValue: 0b00010000000000100000000000000000,
                secondaryConfig: { command: [EXTERNAL_SENSOR_MODE << 1, 0x33] },
                valueLength: [4, 4],
            },
            extsensor1: {
                id: 'extsensor1',
                configValue: 0b00000000000000100000000000000000,
                cmdCode: 0x0a,
                secondaryConfig: { command: [EXTERNAL_SENSOR_MODE << 1, 0x00] },
                valType: { type: 'float', count: 1 },
                expectedVals: [2.0],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                valueLength: [4],
            },
            extsensor2: {
                id: 'extsensor2',
                configValue: 0b00010000000000000000000000000000,
                cmdCode: 0x45,
                secondaryConfig: { command: [EXTERNAL_SENSOR_MODE << 1, 0x00] },
                valType: { type: 'float', count: 1 },
                expectedVals: [2.0],
                toleranceRange: 0.005,
                defaultVal: 99.99,
                valueLength: [4],
            },
        },
        namePrefix: 'PL ',
        thirdPartyConfigs: [],
        defaultFrequencyIndex: 5,
        defaultDataConfigs: ['tem_pro'],
        renameCommand: [0x50, 0x03],
        shutdownCommand: [0x46, 0xe3],
        restartCommand: [0x44, 0xd9],
        enableSaveCommand: [0x40, 0xc1],
        saveCommand: [0x42, 0x09],
        memoryStatusCommand: [0x23],
        startRecordingToMemoryCommand: [0x6a, 0x01],
        stopRecordingToMemoryCommand: [0x6a, 0x00],
        downloadFromMemoryCommand: [0x4c, 0x76],
        clearMemoryCommand: [0x4c, 0x97],
        frequencyConfigs: thermo21FrequencyConfigs,
        maxNumSensorBytesInChunk: 237,
        maxNumBLEPayloadBytes: 244,
    },
    /*
    static airdfutarg: DeviceConfig = {
      id: 'airdfutarg',
      sensorConfigs: [
        ServiceConfigs.dfu1,
        ServiceConfigs.dfu2,
        ServiceConfigs.dfu3,
      ],
      thirdPartyConfigs: [],
    };
     */
];
