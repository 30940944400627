import {Inject, Injectable, OnDestroy} from '@angular/core';

import {Observable, of, ReplaySubject} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

import {
  APIMessages as APIM,
  UtilsPL2 as U,
  UserUtilsPL2 as UU,
} from '@common/utils/dist/index.js';

import {APIError} from '@utils/api-errors';

import {WebsocketService} from './websocket.service';
import {RouteStore} from '@cloudlab/stores/route.store';
import {UserStore} from '@cloudlab/stores/user.store';
import {userChangeFilter} from '@cloudlab/utils/data-view-pipes';

@Injectable({
  providedIn: 'root',
})
export class UserApiService implements OnDestroy {
  private _destroyed$ = new ReplaySubject<boolean>();

  constructor(
    private ws: WebsocketService,
    private routeStore: RouteStore,
    private userStore: UserStore,
    @Inject('SESSIONSTORAGE')
    private sessionStorage: Storage,
  ) {
    this.routeStore.state$
      .pipe(
        map((state) => state.utm_source),
        distinctUntilChanged(),
        filter((utmCode) => !U.isEmpty(utmCode)),
        tap((utmCode) => this.sessionStorage.setItem('utmCode', utmCode)),
        takeUntil(this._destroyed$),
      )
      .subscribe();

    this.userStore.state$
      .pipe(
        userChangeFilter(),
        filter((user) => !U.isEmpty(user) && !UU.isUnconfirmed(user)),
        mergeMap((user) => {
          const utmCode = this.sessionStorage.getItem('utmCode');
          return this.ws
            .sendEvent({
              user: user,
              ts: U.timestamp(),
              c: [
                U.excludeEmptyProperties({
                  utm: utmCode,
                  a: APIM.UserAction.SignIn,
                  p: user,
                }),
              ],
            }, APIM.RequestIntent.WSSNotifications)
            .pipe(
              mergeMap(() => {
                if (!U.isEmpty(user.init)) {
                  return this.userStore.deleteUserAttribute('init');
                } else {
                  return of(void 0);
                }
              }),
              catchError((err) => {
                console.warn(err);
                return of(APIError.buildError(err));
              }),
              take(1),
            );
        }),
        takeUntil(this._destroyed$),
      )
      .subscribe();
  }

  ngOnDestroy() {
    this._destroyed$.next(true);
    this._destroyed$.complete();
  }
}
