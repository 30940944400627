import { CommProtocol, DigitalPowerMode, Baudrate, } from './connection-types.js';
import { SensorDriverHandler } from './sensor-driver-handler.js';
import * as OdysseyCommandCodes from './odyssey-command-codes.js';
// ascii codes for commands/keywords
export const MODE_OUTPUT = 'M'.charCodeAt(0);
export const PPO2 = 'O'.charCodeAt(0);
export const O2 = '%'.charCodeAt(0);
export const TEMPERATURE = 'T'.charCodeAt(0);
export const BAROMETRIC_PRESSURE = 'P'.charCodeAt(0);
export const ALL = 'A'.charCodeAt(0);
export const SENSOR_STATUS = 'e'.charCodeAt(0);
export const SENSOR_INFORMATION = '#'.charCodeAt(0);
export const SPACE = ' '.charCodeAt(0);
export const CARRIAGE_RETURN = '\r'.charCodeAt(0);
export const TERMINATE = '\n'.charCodeAt(0);
export const ERROR_RESPONSE = 'E'.charCodeAt(0);
// luminox output modes
export var LuminOxMode;
(function (LuminOxMode) {
    LuminOxMode[LuminOxMode["LUMINOX_MODE_STREAMING"] = 0] = "LUMINOX_MODE_STREAMING";
    LuminOxMode[LuminOxMode["LUMINOX_MODE_POLLING"] = 1] = "LUMINOX_MODE_POLLING";
    LuminOxMode[LuminOxMode["LUMINOX_MODE_OFF"] = 2] = "LUMINOX_MODE_OFF";
    LuminOxMode[LuminOxMode["LUMINOX_MODE_DEFAULT"] = 0] = "LUMINOX_MODE_DEFAULT";
})(LuminOxMode || (LuminOxMode = {}));
// luminox sensor information
export var LuminOxSensorInfo;
(function (LuminOxSensorInfo) {
    LuminOxSensorInfo[LuminOxSensorInfo["LUMINOX_INFO_DATE_OF_MFG"] = 0] = "LUMINOX_INFO_DATE_OF_MFG";
    LuminOxSensorInfo[LuminOxSensorInfo["LUMINOX_INFO_SERIAL_NUM"] = 1] = "LUMINOX_INFO_SERIAL_NUM";
    LuminOxSensorInfo[LuminOxSensorInfo["LUMINOX_INFO_SW_VER"] = 2] = "LUMINOX_INFO_SW_VER";
})(LuminOxSensorInfo || (LuminOxSensorInfo = {}));
// luminox return codes
export var LuminOxRetcode;
(function (LuminOxRetcode) {
    /*
      Error: USART Receiver Overflow
      Possible Cause: No <Terminator> received before overflow
      Action: - Check USART Setup
          - Confirm correct termination
      */
    LuminOxRetcode[LuminOxRetcode["LUMINOX_ERR_RX_OVERFLOW"] = 0] = "LUMINOX_ERR_RX_OVERFLOW";
    /*
      Error: Invalid Command
      Possible Cause: Unrecognised <Command> received
      Action: - Check command is valid
          - Check command is uppercase ('M' instead of 'm')
      */
    LuminOxRetcode[LuminOxRetcode["LUMINOX_ERR_INVALID_CMD"] = 1] = "LUMINOX_ERR_INVALID_CMD";
    /*
      Error: Invalid Frame
      Possible Cause: Incorrect character in frame <Separator>
      Action: - Check correct separator is used
      */
    LuminOxRetcode[LuminOxRetcode["LUMINOX_ERR_INVALID_FRAME"] = 2] = "LUMINOX_ERR_INVALID_FRAME";
    /*
      Error: Invalid Argument
      Possible Cause: <Argument> not allowed in limits
      Action: - Check argument is no longer than 6 characters
          - Check argument is within limits
          - Check argument is available for command
      */
    LuminOxRetcode[LuminOxRetcode["LUMINOX_ERR_INVALID_ARG"] = 3] = "LUMINOX_ERR_INVALID_ARG";
    /*
      Error: Invalid Mode Type
      Cause: luminox_set_output_mode() was given an invalid luminox_mode_t
      Action:	- Check luminox_set_ouput_mode() is given a valid luminox_mode_t
      */
    LuminOxRetcode[LuminOxRetcode["LUMINOX_ERR_INVALID_MODE"] = 4] = "LUMINOX_ERR_INVALID_MODE";
    /*
      Error: Invalid Info Type
      Cause: luminox_request_sensor_info() was given an invalid luminox_sensor_info_t
      Action:	- Check luminox_request_sensor_info() is given a valid luminox_sensor_info_t
      */
    LuminOxRetcode[LuminOxRetcode["LUMINOX_ERR_INVALID_INFO"] = 5] = "LUMINOX_ERR_INVALID_INFO";
    /*
      Error: UART Response Timeout
      Cause: luminox library function waited too long for response from sensor
      Action: - Check that the sensor is in not in off mode
          - Check that the micro is sending and receiving data from the sensor
      */
    LuminOxRetcode[LuminOxRetcode["LUMINOX_ERR_TIMEOUT"] = 6] = "LUMINOX_ERR_TIMEOUT";
    LuminOxRetcode[LuminOxRetcode["LUMINOX_ERROR"] = 7] = "LUMINOX_ERROR";
    LuminOxRetcode[LuminOxRetcode["LUMINOX_SUCCESS"] = 8] = "LUMINOX_SUCCESS";
})(LuminOxRetcode || (LuminOxRetcode = {}));
export class LuminOxHandler extends SensorDriverHandler {
    constructor() {
        super();
        this.err_code = LuminOxRetcode.LUMINOX_SUCCESS; // return code from sensor
    }
    // luminoxTX()
    // params:
    // tx - bytes to send to device
    // size - number of bytes to send to device
    //
    // method to write a command to the sensor
    async luminoxTX(tx, size) {
        if (tx == null || size < 1) {
            throw new Error('luminoxTX - invalid command');
        }
        await this.conn.writeCommand([
            OdysseyCommandCodes.DIGITAL_INTERFACE_CTRL << 1,
            OdysseyCommandCodes.UART_WRITE,
            this.interfaceID,
            size,
            ...tx,
        ]);
        // TODO: read back DIGITAL_INTERFACE_CMD to validate the command has finished
    }
    // luminoxReadData()
    // params:
    //
    // method to read back response from sensor
    async luminoxReadData() {
        if (this.conn == null) {
            throw new Error('luminoxReadData - connection object is null');
        }
        // send READ_DIGITAL_INTERFACE_X cmd
        if (this.interfaceID == 1) {
            await this.conn.writeCommand([
                (OdysseyCommandCodes.READ_DIGITAL_INTERFACE_1 << 1) + 1,
            ]);
        }
        else if (this.interfaceID == 2) {
            await this.conn.writeCommand([
                (OdysseyCommandCodes.READ_DIGITAL_INTERFACE_2 << 1) + 1,
            ]);
        }
        else {
            throw new Error('luminoxReadData - invalid interface ID');
        }
        // read back and process response
        await this.conn.readCommand().then((result) => {
            this.data = [];
            for (let i = 2; i < result.byteLength; i++) {
                this.data.push(result.getUint8(i));
            }
            this.processData();
        });
    }
    /*
          @brief Function for initializing communication with the LuminOx sensor
          
          @note First sets the output mode to polling to request and print out sensor information, then sets the output mode
          to default mode, which is set to be off. Then resets all of the static variables keeping track of state and recent sensor readings.
  
          returns whether init was a success or not
      */
    async init(conn, port) {
        this.conn = conn;
        this.interfaceID = port;
        // set measurements to zero
        this.current_ppO2 = 0.0;
        this.current_O2 = 0.0;
        this.current_temp = 0.0;
        this.current_barometric_pressure = 0.0;
        this.err_code = LuminOxRetcode.LUMINOX_SUCCESS;
        await this.luminox_set_output_mode(LuminOxMode.LUMINOX_MODE_POLLING);
        await this.luminox_request_sensor_info(LuminOxSensorInfo.LUMINOX_INFO_DATE_OF_MFG);
        await this.luminox_request_sensor_info(LuminOxSensorInfo.LUMINOX_INFO_SERIAL_NUM);
        await this.luminox_request_sensor_info(LuminOxSensorInfo.LUMINOX_INFO_SW_VER);
        // set output mode to default (streaming data)
        await this.luminox_set_output_mode(LuminOxMode.LUMINOX_MODE_DEFAULT);
        this.current_mode = LuminOxMode.LUMINOX_MODE_DEFAULT;
        if (this.err_code == LuminOxRetcode.LUMINOX_SUCCESS) {
            return true;
        }
        else {
            return false;
        }
    }
    /*
          @brief Function for setting the output mode of the luminox sensor
  
          @note Response will be “M xx\r\n” Where xx equals the Argument of the command
  
          @param[in] mode Desired mode for the sensor (streaming, polling, off)
  
          @return luminox_retcode_t Either success or one of the error codes
      */
    async luminox_set_output_mode(mode) {
        let msg = this.hex('M x\r\n');
        switch (mode) {
            case LuminOxMode.LUMINOX_MODE_STREAMING:
                msg[2] = '0'.charCodeAt(0);
                break;
            case LuminOxMode.LUMINOX_MODE_POLLING:
                msg[2] = '1'.charCodeAt(0);
                break;
            case LuminOxMode.LUMINOX_MODE_OFF:
                msg[2] = '2'.charCodeAt(0);
                break;
            default:
                this.err_code = LuminOxRetcode.LUMINOX_ERR_INVALID_MODE;
                return this.err_code;
        }
        await this.luminoxTX(msg, 5);
        await this.luminoxReadData();
        return this.err_code;
    }
    /*
          @brief Function for retrieving the current output mode of the luminox sensor
  
          @return luminox_mode_t returns the current_mode (streaming, polling, or off)
      */
    luminox_get_output_mode() {
        return this.current_mode;
    }
    /*
          @brief Function for requesting the current ppO2 value from the sensor
  
          @note Response will be “O xxxx.x\r\n” Where xxxx.x equals the ppO2 in mbar
  
          @return luminox_retcode_t Either success or one of the error codes
      */
    async luminox_request_ppO2() {
        let msg = this.hex('O\r\n');
        await this.luminoxTX(msg, 3);
        await this.luminoxReadData();
        return this.err_code;
    }
    /*
          @brief Function for getting the current ppO2 value
  
          @note This function returns whatever is stored in the current_ppO2 variable, which may be out of date. Call luminox_request_ppO2() first.
  
          @return Floating point integer of the ppO2 value
      */
    luminox_get_ppO2() {
        return this.current_ppO2;
    }
    /*
          @brief Function for requesting the current O2 value from the sensor
  
          @note Response will be “% xxx.xx\r\n" Where xxx.xx equals the O2 in percent %
  
          @return luminox_retcode_t Either success or one of the error codes
      */
    async luminox_request_O2() {
        let msg = this.hex('%\r\n');
        await this.luminoxTX(msg, 3);
        await this.luminoxReadData();
        return this.err_code;
    }
    /*
          @brief Function for getting the current O2 value
  
          @note This function returns whatever is stored in the current_O2 variable, which may be out of date. Call luminox_request_O2() first.
  
          @return Floating point integer of the O2 value
      */
    luminox_get_O2() {
        return this.current_O2;
    }
    /*
          @brief Function for requesting the current Temperature inside the sensor
  
          @note Response will be “T yxx.x\r\n” Where y equals the sign “-” or “+” and xx.x equals the temperature in °C
  
          @return luminox_retcode_t Either success or one of the error codes
      */
    async luminox_request_temp() {
        let msg = this.hex('T\r\n');
        await this.luminoxTX(msg, 3);
        await this.luminoxReadData();
        return this.err_code;
    }
    /*
          @brief Function for getting the current temp value
  
          @note This function returns whatever is stored in the current_temp variable, which may be out of date. Call luminox_request_temp() first.
  
          @return Floating point integer of the temp value
      */
    luminox_get_temp() {
        return this.current_temp;
    }
    /*
          @brief Function for requesting the current barometric pressure
  
          @note Response will be “P xxxx\r\n” Where xxxx equals the pressure in mbar
  
          @note Only valid for sensors fitted with barometric pressure sensor. Otherwise returns "------"
  
          @return luminox_retcode_t Either success or one of the error codes
      */
    async luminox_request_barometric_pressure() {
        let msg = this.hex('P\r\n');
        await this.luminoxTX(msg, 3);
        await this.luminoxReadData();
        return this.err_code;
    }
    /*
          @brief Function for getting the current barometric pressure value
  
          @note This function returns whatever is stored in the current_barometric_pressure variable, which may be out of date. Call luminox_request_barometric_pressure() first.
  
          @return Floating point integer of the barometric pressure value
      */
    luminox_get_barometric_pressure() {
        return this.current_barometric_pressure;
    }
    /*
          @brief Function for requesting the current sensor status
  
          @note Response will be “e 0000\r\n” = Sensor Status Good
                  “e xxxx\r\n” = Any other response, contact SST Sensing for advice
  
          @return luminox_retcode_t Either success or one of the error codes
      */
    async luminox_request_sensor_status() {
        let msg = this.hex('e\r\n');
        await this.luminoxTX(msg, 3);
        await this.luminoxReadData();
        return this.err_code;
    }
    /*
          @brief Function for requesting ppO2, O2, temperature, barometric pressure, and sensor status
  
          @note Response will be “O xxxx.x T yxx.x P xxxx % xxx.xx e xxxx\r\n”
  
          @return luminox_retcode_t Either success or one of the error codes
      */
    async luminox_request_all() {
        let msg = this.hex('A\r\n');
        await this.luminoxTX(msg, 3);
        await this.luminoxReadData();
        return this.err_code;
    }
    /*
          @brief Function for requesting the given sensor information
  
          @note Response will be “# YYYYY DDDDD\r\n” - Date of manufacture
                  “# xxxxx xxxxx\r\n” - Serial number
                  “# xxxxx\r\n”       - Software revision
  
          @param[in] info The desired information to be requested (date of mfg, serial #, sw version)
  
          @return luminox_retcode_t Either success or one of the error codes
      */
    async luminox_request_sensor_info(info) {
        let msg = this.hex('# x\r\n');
        switch (info) {
            case LuminOxSensorInfo.LUMINOX_INFO_DATE_OF_MFG:
                msg[2] = '0'.charCodeAt(0);
                break;
            case LuminOxSensorInfo.LUMINOX_INFO_SERIAL_NUM:
                msg[2] = '1'.charCodeAt(0);
                break;
            case LuminOxSensorInfo.LUMINOX_INFO_SW_VER:
                msg[2] = '2'.charCodeAt(0);
                break;
            default:
                this.err_code = LuminOxRetcode.LUMINOX_ERR_INVALID_INFO;
                return this.err_code;
        }
        await this.luminoxTX(msg, 5);
        await this.luminoxReadData();
        return this.err_code;
    }
    /*
          @brief Function for handling any unsuccessfull requests
  
          @note Called in luminox_process_response if an error message is received from the sensor,
          uses what's in luminox_data[] to process the error
  
          @param[in] luminox_handler Pointer of library handler
  
          @return luminox_retcode_t returns one of the defined luminox error codes
      */
    luminox_error_handler() {
        switch (this.data[3]) {
            case 0x30:
                this.err_code = LuminOxRetcode.LUMINOX_ERR_RX_OVERFLOW;
                throw new Error('LuminOx Sensor Error: USART Receiver Overflow');
            case 0x31:
                this.err_code = LuminOxRetcode.LUMINOX_ERR_INVALID_CMD;
                throw new Error('LuminOx Sensor Error: Invalid Command');
            case 0x32:
                this.err_code = LuminOxRetcode.LUMINOX_ERR_INVALID_FRAME;
                throw new Error('LuminOx Sensor Error: Invalid Frame');
            case 0x33:
                this.err_code = LuminOxRetcode.LUMINOX_ERR_INVALID_ARG;
                throw new Error('LuminOx Sensor Error: Invalid Argument');
        }
        return LuminOxRetcode.LUMINOX_ERROR;
    }
    /*
          @brief Function for printing the response from the luminox sensor
  
          @note The LuminOx sensor responds in ASCII encoded messages
  
          @note Updates the current_x variables and err_code
      */
    processData(data = null) {
        if (data != null) {
            this.data = [];
            this.data = data;
        }
        if (this.data.length == 0) {
            this.err_code = LuminOxRetcode.LUMINOX_ERROR;
            console.error(`LuminOx data empty, make sure sensor is plugged into digital interface ${this.interfaceID}`);
            return [];
        }
        let i = 0;
        let exitLoop = false;
        let output = '';
        while (this.data[i] != TERMINATE && !exitLoop && i < this.data.length) {
            output += String.fromCharCode(this.data[i]);
            switch (this.data[i]) {
                case ERROR_RESPONSE:
                    this.err_code = this.luminox_error_handler();
                    exitLoop = true;
                    break;
                case MODE_OUTPUT:
                    switch (this.data[i + 3]) {
                        case 0x30:
                            this.current_mode = LuminOxMode.LUMINOX_MODE_STREAMING;
                            break;
                        case 0x31:
                            this.current_mode = LuminOxMode.LUMINOX_MODE_POLLING;
                            break;
                        case 0x32:
                            this.current_mode = LuminOxMode.LUMINOX_MODE_OFF;
                            break;
                    }
                    exitLoop = true;
                    break;
                case PPO2:
                    let ppo2_data = [];
                    i += 2; // increment by 2 to move index to start of scaling factor
                    ppo2_data = this.data.slice(i, i + 5);
                    let ppo2_string = String.fromCharCode(...ppo2_data);
                    this.current_ppO2 = parseFloat(ppo2_string);
                    i += 5; // increment by 5 to move the index to the end of the string or next value in the response
                    break;
                case O2:
                    let o2_data = [];
                    i += 2;
                    o2_data = this.data.slice(i, i + 5);
                    let o2_string = String.fromCharCode(...o2_data);
                    this.current_O2 = parseFloat(o2_string);
                    i += 5;
                    break;
                case TEMPERATURE:
                    let temp_data = [];
                    i += 2;
                    temp_data = this.data.slice(i, i + 5);
                    let temp_string = String.fromCharCode(...temp_data);
                    this.current_temp = parseFloat(temp_string);
                    i += 4;
                    break;
                case BAROMETRIC_PRESSURE:
                    let barometric_data = [];
                    i += 2;
                    barometric_data = this.data.slice(i, i + 5);
                    let barometric_string = String.fromCharCode(...barometric_data);
                    this.current_barometric_pressure = parseFloat(barometric_string);
                    i += 3;
                    break;
                case SENSOR_STATUS:
                    console.log('LuminOx Sensor Status: ');
                    i++;
                    break;
                case SENSOR_INFORMATION:
                    console.log('LuminOx Sensor Information: ');
                    i++;
                    break;
                case SPACE:
                    i++;
                    break;
                case CARRIAGE_RETURN:
                    i++;
                    break;
                default:
                    if (this.data[i] >= 48 && this.data[i] <= 57) {
                        // Handle the case when this.data[i] is between '0' and '9'
                        i++;
                    }
                    else {
                        // Handle any other unrecognized command
                        this.err_code = LuminOxRetcode.LUMINOX_ERROR;
                        i++;
                    }
                    break;
            }
        }
        this.data = []; // reset data
        console.log(output);
        // return processed O2, temperature and pressure values
        return [
            ['ppO2', this.current_ppO2, 'mbar'],
            ['O2', this.current_O2, '%'],
            ['Temp', this.current_temp, '°C'],
            ['Barometric Pressure', this.current_barometric_pressure, 'mbar'],
        ];
    }
}
export const LuminOx = {
    name: 'LuminOx O2 Sensor',
    pwrEn: true,
    pwrMode: DigitalPowerMode.DIGITAL_RAIL_5V,
    comm: CommProtocol.UART,
    ioEnable: true,
    commConfig: {
        address: 0x0000,
        baudRate: Baudrate.BAUDRATE_9600,
        pwmFreq: 0x0000,
        pwmDC: 0x0000,
        gpio: 0x0000,
    },
    handler: new LuminOxHandler(),
};
