import { updateFullOdysseySystemConfig, } from './odyssey-system-config.js';
import { ExplorIR } from './explorir-driver.js';
import { LuminOx } from './luminox-driver.js';
// configureDigitalInterface()
// params:
// interfaceID - digital interface 1 or 2
// sensor - the sensor that's plugged into the given digital interface port
// sysConfig - system config object for the connected Odyssey device
// conn - connection object
//
// Configures the given digital interface port for the given sensor by updating the odyssey system config
export async function configureDigitalInterface(interfaceID, sensor, sysConfig, conn) {
    if (conn == null) {
        throw new Error('configureDigitalInterface - connection object is null');
    }
    if (sensor == null) {
        throw new Error('configureDigitalInterface - sensor is null');
    }
    if (sysConfig == null) {
        throw new Error('configureDigitalInterface - sysConfig is null');
    }
    if (interfaceID == 1) {
        // set pwr_en
        let val = sensor.pwrEn ? 1 : 0;
        val <<= sysConfig.DIG1.pwrEnable.offset;
        sysConfig.DIG1.value &= ~BigInt(sysConfig.DIG1.pwrEnable.mask);
        sysConfig.DIG1.value |= BigInt(val);
        // set pwr_mode
        val = sensor.pwrMode;
        val <<= sysConfig.DIG1.pwrMode.offset;
        sysConfig.DIG1.value &= ~BigInt(sysConfig.DIG1.pwrMode.mask);
        sysConfig.DIG1.value |= BigInt(val);
        // set io_cfg
        val = sensor.comm;
        val <<= sysConfig.DIG1.ioConfig.offset;
        sysConfig.DIG1.value &= ~BigInt(sysConfig.DIG1.ioConfig.mask);
        sysConfig.DIG1.value |= BigInt(val);
        // set io_en
        val = sensor.ioEnable ? 1 : 0;
        val <<= sysConfig.DIG1.ioEnable.offset;
        sysConfig.DIG1.value &= ~BigInt(sysConfig.DIG1.ioEnable.mask);
        sysConfig.DIG1.value |= BigInt(val);
        // set address
        val = sensor.commConfig.address;
        val <<= sysConfig.DIG1.address.offset;
        sysConfig.DIG1.value &= ~BigInt(sysConfig.DIG1.address.mask);
        sysConfig.DIG1.value |= BigInt(val);
        // set baudrate
        val = sensor.commConfig.baudRate;
        val <<= sysConfig.DIG1.baudRate.offset;
        sysConfig.DIG1.value &= ~BigInt(sysConfig.DIG1.baudRate.mask);
        sysConfig.DIG1.value |= BigInt(val);
        // set pwm_freq
        val = sensor.commConfig.pwmFreq;
        val <<= sysConfig.DIG1.pwmFreq.offset;
        sysConfig.DIG1.value &= ~BigInt(sysConfig.DIG1.pwmFreq.mask);
        sysConfig.DIG1.value |= BigInt(val);
        // set pwm_dc
        val = sensor.commConfig.pwmDC;
        val <<= sysConfig.DIG1.pwmDC.offset;
        sysConfig.DIG1.value &= ~BigInt(sysConfig.DIG1.pwmDC.mask);
        sysConfig.DIG1.value |= BigInt(val);
        // set gpio
        val = sensor.commConfig.gpio;
        val <<= sysConfig.DIG1.gpio.offset;
        sysConfig.DIG1.value &= ~BigInt(sysConfig.DIG1.gpio.mask);
        sysConfig.DIG1.value |= BigInt(val);
        // update digital 1 byte array
        for (let i = 0; i < sysConfig.DIG1.byteArray.length; i++) {
            sysConfig.DIG1.byteArray[i] = Number((sysConfig.DIG1.value >> BigInt(i * 8)) & BigInt(0xff));
        }
    }
    else if (interfaceID == 2) {
        // set pwr_en
        let val = sensor.pwrEn ? 1 : 0;
        val <<= sysConfig.DIG2.pwrEnable.offset;
        sysConfig.DIG2.value &= ~BigInt(sysConfig.DIG2.pwrEnable.mask);
        sysConfig.DIG2.value |= BigInt(val);
        // set pwr_mode
        val = sensor.pwrMode;
        val <<= sysConfig.DIG2.pwrMode.offset;
        sysConfig.DIG2.value &= ~BigInt(sysConfig.DIG2.pwrMode.mask);
        sysConfig.DIG2.value |= BigInt(val);
        // set io_cfg
        val = sensor.comm;
        val <<= sysConfig.DIG2.ioConfig.offset;
        sysConfig.DIG2.value &= ~BigInt(sysConfig.DIG2.ioConfig.mask);
        sysConfig.DIG2.value |= BigInt(val);
        // set io_en
        val = sensor.ioEnable ? 1 : 0;
        val <<= sysConfig.DIG2.ioEnable.offset;
        sysConfig.DIG2.value &= ~BigInt(sysConfig.DIG2.ioEnable.mask);
        sysConfig.DIG2.value |= BigInt(val);
        // set address
        val = sensor.commConfig.address;
        val <<= sysConfig.DIG2.address.offset;
        sysConfig.DIG2.value &= ~BigInt(sysConfig.DIG2.address.mask);
        sysConfig.DIG2.value |= BigInt(val);
        // set baudrate
        val = sensor.commConfig.baudRate;
        val <<= sysConfig.DIG2.baudRate.offset;
        sysConfig.DIG2.value &= ~BigInt(sysConfig.DIG2.baudRate.mask);
        sysConfig.DIG2.value |= BigInt(val);
        // set pwm_freq
        val = sensor.commConfig.pwmFreq;
        val <<= sysConfig.DIG2.pwmFreq.offset;
        sysConfig.DIG2.value &= ~BigInt(sysConfig.DIG2.pwmFreq.mask);
        sysConfig.DIG2.value |= BigInt(val);
        // set pwm_dc
        val = sensor.commConfig.pwmDC;
        val <<= sysConfig.DIG2.pwmDC.offset;
        sysConfig.DIG2.value &= ~BigInt(sysConfig.DIG2.pwmDC.mask);
        sysConfig.DIG2.value |= BigInt(val);
        // set gpio
        val = sensor.commConfig.gpio;
        val <<= sysConfig.DIG2.gpio.offset;
        sysConfig.DIG2.value &= ~BigInt(sysConfig.DIG2.gpio.mask);
        sysConfig.DIG2.value |= BigInt(val);
        // update digital 1 byte array
        for (let i = 0; i < sysConfig.DIG2.byteArray.length; i++) {
            sysConfig.DIG2.byteArray[i] = Number((sysConfig.DIG2.value >> BigInt(i * 8)) & BigInt(0xff));
        }
    }
    else {
        throw new Error(`${interfaceID} is an invalid digital interface ID`);
    }
    // write updated odyssey system config to device
    await updateFullOdysseySystemConfig(sysConfig, conn);
}
// place holder sensor
export const NoSensor = {
    name: 'None',
    pwrEn: false,
    pwrMode: 0,
    comm: 0,
    ioEnable: false,
    commConfig: {
        address: 0x0000,
        baudRate: 0x0000,
        pwmFreq: 0x0000,
        pwmDC: 0x0000,
        gpio: 0x0000,
    },
    handler: null,
};
export const DigitalInterfaceSensors = [
    NoSensor,
    ExplorIR,
    LuminOx,
];
